import apiService from "./api.service";

class UserProfileService {

	API_ENDPOINT = 'user/profile'

	/**
	 * Retrieves sequence location for a specific user
	 * 
	 * @param username
	 * 
	 * If the call is successful, the response is mapped to the following schema:
	 * 
	 * {[skillid1]: {phase: X, position: Y}, [skillid2]: ...} 
	 * 
	 * There is one field for each skill in the response
	 */
	getUserProfile(username) {
		return apiService.get(`${this.API_ENDPOINT}/${username}`)
			.then(res => {
				let { userprofile } = res.data
				let r = {}
				userprofile.location.forEach(skill => 
					r[skill.skillid] = {
						phase: skill.phase,
						position: skill.position,
						positionstart:skill.positionstart
					}
				)
				return { location: r, approachtype: userprofile.approachtype , language:userprofile.language}
			})
	}

	/**
	 * Updates location for a specific user
	 * 
	 * @param skillid Skill ID of the location that has to be updated
	 * @param phase New phase value that is going to be set
	 * @param currentposition New position value that is going to be set
	 * @param startposition New position value that is going to be set (initial position)
	 * All the fields are required and it will override any data on the DB
	 */
	updateUserProfile(skillid, phase, currentposition,startposition) {
		return apiService.put(`${this.API_ENDPOINT}/${localStorage.getItem('username')}/${skillid}`, { phase, currentposition,startposition })
			.then(res => res.data)
	}

}

export default new UserProfileService();