import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import SkillMenu from './skillmenu';

import badgeService from '../../services/badge.service';

import '../../styles/badges.scss';
import EmptyBadges from '../../assets/img/badges.png'
import BadgeRequestedEnglish from '../../assets/img/badgerequested-en.png';
import BadgeRequestedGreek from '../../assets/img/badgerequested-el.png';
import BadgeRequestedSwedish from '../../assets/img/badgerequested-sv.png';
import BadgeCreativity1 from '../../assets/img/badgecreativity1.png'
import BadgeCreativity2 from '../../assets/img/badgecreativity2.png'
import BadgeCreativity3 from '../../assets/img/badgecreativity3.png'
import BadgeCreativity4 from '../../assets/img/badgecreativity4.png'
import BadgeProblem1 from '../../assets/img/badgeproblem1.png'
import BadgeProblem2 from '../../assets/img/badgeproblem2.png'
import BadgeProblem3 from '../../assets/img/badgeproblem3.png'
import BadgeProblem4 from '../../assets/img/badgeproblem4.png'
import BadgeSocial1 from '../../assets/img/badgesocial1.png'
import BadgeSocial2 from '../../assets/img/badgesocial2.png'
import BadgeSocial3 from '../../assets/img/badgesocial3.png'
import BadgeSocial4 from '../../assets/img/badgesocial4.png'
import BadgeCommunication1 from '../../assets/img/badgecommunication1.png'
import BadgeCommunication2 from '../../assets/img/badgecommunication2.png'
import BadgeCommunication3 from '../../assets/img/badgecommunication3.png'
import BadgeCommunication4 from '../../assets/img/badgecommunication4.png'
import BadgeCollaboration1 from '../../assets/img/badgecollaboration1.png'
import BadgeCollaboration2 from '../../assets/img/badgecollaboration2.png'
import BadgeCollaboration3 from '../../assets/img/badgecollaboration3.png'
import BadgeCollaboration4 from '../../assets/img/badgecollaboration4.png'

import BadgeCreativity1Disabled from '../../assets/img/badgecreativity1disabled.png'
import BadgeCreativity2Disabled from '../../assets/img/badgecreativity2disabled.png'
import BadgeCreativity3Disabled from '../../assets/img/badgecreativity3disabled.png'
import BadgeCreativity4Disabled from '../../assets/img/badgecreativity4disabled.png'
import BadgeProblem1Disabled from '../../assets/img/badgeproblem1disabled.png'
import BadgeProblem2Disabled from '../../assets/img/badgeproblem2disabled.png'
import BadgeProblem3Disabled from '../../assets/img/badgeproblem3disabled.png'
import BadgeProblem4Disabled from '../../assets/img/badgeproblem4disabled.png'
import BadgeSocial1Disabled from '../../assets/img/badgesocial1disabled.png'
import BadgeSocial2Disabled from '../../assets/img/badgesocial2disabled.png'
import BadgeSocial3Disabled from '../../assets/img/badgesocial3disabled.png'
import BadgeSocial4Disabled from '../../assets/img/badgesocial4disabled.png'
import BadgeCommunication1Disabled from '../../assets/img/badgecommunication1disabled.png'
import BadgeCommunication2Disabled from '../../assets/img/badgecommunication2disabled.png'
import BadgeCommunication3Disabled from '../../assets/img/badgecommunication3disabled.png'
import BadgeCommunication4Disabled from '../../assets/img/badgecommunication4disabled.png'
import BadgeCollaboration1Disabled from '../../assets/img/badgecollaboration1disabled.png'
import BadgeCollaboration2Disabled from '../../assets/img/badgecollaboration2disabled.png'
import BadgeCollaboration3Disabled from '../../assets/img/badgecollaboration3disabled.png'
import BadgeCollaboration4Disabled from '../../assets/img/badgecollaboration4disabled.png'


class Badges extends Component {

	constructor(props) {
		super(props)
		this.state = {
			badges: {},
			userBadges: {},
			selectedSkill: -1,
		}
		
		let language = this.props.language
		this.changeLanguage(language);
	}

	changeLanguage = (lng) => {
		this.props.i18n.changeLanguage(lng);
	};

	componentDidMount() {
		this.props.skills.forEach(skill => {
			badgeService.getBadges(skill._id).then(data => {
				const { badges } = this.state
				badges[skill._id] = data
				this.setState({ badges })
			})
		})
		badgeService.getUserBadges(localStorage.getItem('username')).then(userBadges => this.setState({ userBadges }))
	}

	skillExist = (skillID = this.state.selectedSkill) => !!this.state.badges[skillID]

	changeBadges = (skillid) => this.setState({ selectedSkill: this.skillExist(skillid) ? skillid : -1 })

	getClassNamePerLevel = (level) => {
		switch(level) {
			case 1: return 'one'
			case 2: return 'two'
			case 3: return 'three'
			case 4: return 'four'
			default: return ''
		}
	}

	getStatusStyle = (badge) => {
		const badgeData = this.state.badges[this.state.selectedSkill].find(b => b._id === badge._id)
		const userBadge = this.state.userBadges.find(b => b.badgeid === badge._id)
		if (!badgeData || !userBadge) {
			return 'disabled'
		} else {
			switch(userBadge.status) {
				case 2:
					return 'requested'
				case 3:
					return 'approved'
				default: case 1:
					return 'disabled'
			}
		}
	}

	getRequestedBanner = () => {
		switch(String(this.props.language)) {
			case 'el':
				return BadgeRequestedGreek;
			case 'sv':
				return BadgeRequestedSwedish;
			default:
				return BadgeRequestedEnglish;
		}
	}

	getImage = (badge) => {
		const badgeStatus = this.state.userBadges.sort((a, b) => b.status - a.status).find(a => a.badgeid === badge._id)
		switch(badge.skillid) {
			case 1:
				switch(badge.phase) {
					case 4: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCollaboration4 : BadgeCollaboration4Disabled
					case 3: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCollaboration3 : BadgeCollaboration3Disabled
					case 2: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCollaboration2 : BadgeCollaboration2Disabled
					default: case 1: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCollaboration1 : BadgeCollaboration1Disabled
				}
			case 2:
				switch(badge.phase) {
					case 4: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCreativity4 : BadgeCreativity4Disabled
					case 3: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCreativity3 : BadgeCreativity3Disabled
					case 2: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCreativity2 : BadgeCreativity2Disabled
					default: case 1: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCreativity1 : BadgeCreativity1Disabled
				}
			case 3:
				switch(badge.phase) {
					case 4: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeProblem4 : BadgeProblem4Disabled
					case 3: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeProblem3 : BadgeProblem3Disabled
					case 2: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeProblem2 : BadgeProblem2Disabled
					default: case 1: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeProblem1 : BadgeProblem1Disabled
				}
			case 4:
				switch(badge.phase) {
					case 4: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeSocial4 : BadgeSocial4Disabled
					case 3: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeSocial3 : BadgeSocial3Disabled
					case 2: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeSocial2 : BadgeSocial2Disabled
					default: case 1: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeSocial1 : BadgeSocial1Disabled
				}
			case 5:
				switch(badge.phase) {
					case 4: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCommunication4 : BadgeCommunication4Disabled
					case 3: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCommunication3 : BadgeCommunication3Disabled
					case 2: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCommunication2 : BadgeCommunication2Disabled
					default: case 1: return (!!badgeStatus && badgeStatus.status === 3) ? BadgeCommunication1 : BadgeCommunication1Disabled
				}
			default:
		}
	}

	render() {
		let { t } = this.props
		return (
			<div id='badges-component' className='d-flex flex-row'>
				<SkillMenu skills={this.props.skills} activeSkill={this.state.selectedSkill} onSkillSelected={this.changeBadges} />
				<div className="content d-flex flex-wrap justify-content-start align-items-start">
				{
						this.skillExist() ?
						this.state.badges[this.state.selectedSkill].map(badge =>
							<div className={`badge box-shadow ${this.getStatusStyle(badge)} ${this.getClassNamePerLevel(badge.phase)}`} key={badge._id} >
								<img src={this.getRequestedBanner()} alt='Requested' className='ribbon' />
								<img src={this.getImage(badge)} alt='Badge' />
								<div className="skill">{t(this.props.skills[this.props.skills.map(skill => skill._id).indexOf(this.state.selectedSkill)].title.toLowerCase())}</div>
								<div className="level">{t("badges.level")} {badge.phase}</div>
							</div>
						)
						:
						<div className="empty d-flex flex-column justify-content-center">
							<img src={EmptyBadges} alt="Empty badges" />
							<div className="text">
							{t('choosea21stcenturybadges')}
							</div>
						</div>
					}
				</div>
			</div>
		)
	}
}

//export default Badges;

export default withTranslation()(Badges);