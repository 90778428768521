import apiService from "./api.service";

class TaskService {

	API_ENDPOINT = 'task'

	getTask(taskid) {
		return apiService.get(`${this.API_ENDPOINT}/${taskid}`)
			.then(res => res.data.task)
	}
	getTaskTranslated(taskid,langid) {
		return apiService.get(`${this.API_ENDPOINT}/${taskid}/${langid}`)
			.then(res => res.data.task)
	}
}

export default new TaskService();