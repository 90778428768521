import apiService from "./api.service";

class PortfolioService {

	API_ENDPOINT = 'user/task'

	getPortfolio(username, skills) {
		return Promise.all(skills.map(skill => apiService.get(`${this.API_ENDPOINT}/${username}/skill/${skill}/images`)))
			.then(data => {
				const allData = []
				data.forEach(entry => allData.push(...entry.data.data))
				return allData
			})
	}

	getImageTexts(username, answerid, count = 3) {
		return apiService.get(`${this.API_ENDPOINT}/${username}/task/${answerid}/next/${count}`)
			.then(res => res.data.data.map(item => {
				const { answers } = item
				if (Array.isArray(answers)) {
					if (answers.length > 0) return answers[0]
					else return ''
				} else return answers

			}))
	}

}

export default new PortfolioService();