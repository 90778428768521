import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import '../../styles/challengesummary.scss';
import CollaborationColorIcon from '../../assets/img/Collaboration-iconsmall.png'
import CreativityColorIcon from '../../assets/img/Creativity-small.png'
import ProblemSolvingColorIcon from '../../assets/img/ProblemSolving-small.png'
import LifeSocialColorIcon from '../../assets/img/Life-Social-Skills-small.png'
import CommunicationColorIcon from '../../assets/img/Communication-small.png'

class ChallengeSummary extends Component {

    SKILL_ICONS = {
        1: CollaborationColorIcon,
        2: CreativityColorIcon,
        3: ProblemSolvingColorIcon,
        4: LifeSocialColorIcon,
        5: CommunicationColorIcon
    }

    constructor(props) {
		super(props)
        this.changeLanguage(this.props.language)
	}
    
    changeLanguage = (lng) => {
        this.props.i18n.changeLanguage(lng);
    };

    render() {
        let {t} = this.props
        return <div id='challengesummary-component' >
            <div className="back" onClick={this.props.goBack}>&lt; {t('back')}</div>
            <div className="header">{t('approach2prompt')}</div>
            <div className="skills d-flex flex-row justify-content-between">
                { this.props.skills.map(skill =>
                    <div key={skill._id} className="skill box-shadow d-flex flex-column justify-content-between">
                        <div className="top">
                            <div className="icon">
                                <img src={this.SKILL_ICONS[skill._id]} alt={`${skill.title} challenges`} />
                            </div>
                            <div className="title">{t(skill.title.toLowerCase())}</div>
                        </div>
                        <div className="bottom">
                            <div className="number">{!!skill.challenges ? skill.challenges.length : 0}</div>
                            <div className="challenges">{t('challenges')}</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="start primary-button" onClick={this.props.goNext}>{t('start')}</div>
        </div>
    }
}

export default withTranslation()(ChallengeSummary);