import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { AiFillStar } from 'react-icons/ai';

import '../../styles/ratingchallenge.scss';

class RatingChallenge extends Component {

    constructor() {
        super()
        this.state = {}
    }

    handleChange = (statementID, answerValue) => {
        if (!!statementID && !!answerValue) {
            this.setState({ [statementID]: answerValue }, () => {
                if (Object.entries(this.state).length === this.props.challenge.body.length)
                    this.props.onAnswer(Object.values(this.state))
            })
        }
    }

    render() {
        const { challenge } = this.props
        return (
            <Container id='ratingchallenge-component'>
                {
                    challenge.body.map(statement =>
                        <div className="statement" key={statement.tagid}>
                            <div className="text">{statement.tag}</div>
                            <div className="rating container-fluid row">
                                {
                                    [1, 2, 3, 4, 5].map(value =>
                                        <div
                                            className={`icon ${!!this.state[statement.tagid] && this.state[statement.tagid] >= value && 'active'}`}
                                            onClick={() => this.handleChange(statement.tagid, value)}><AiFillStar /></div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </Container>
        )
    }
}

export default RatingChallenge;