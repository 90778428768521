import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import taskService from '../../services/task.service';
import userTaskService from '../../services/usertask.service';

import ChallengeContainer from '../challenges/challengecontainer';

import '../../styles/exampleupload.scss';
import Stepper from '../stepper';
import { Col, Row } from 'react-bootstrap';

class ExampleUpload extends Component {

	CHALLENGE_FLOW = [
		{
			order: 0,
			taskid: 1001,
			task: null
		},
		{
			order: 1,
			taskid: 1002,
			task: null
		},
		{
			order: 2,
			taskid: 1003,
			task: null
		},
		{
			order: 3,
			taskid: 1004,
			task: null
		}
	]

	constructor(props) {
		super(props)
		this.state = {
			flowIndex: 0,
			tasks: {},
			image: null
		}
	}

	componentDidMount() {
		this.CHALLENGE_FLOW.forEach(step => {
			taskService.getTaskTranslated(step.taskid,this.props.language).then(data => {
				const { tasks } = this.state
				data.skill = this.props.extras.skill
				tasks[step.order] = this.props.replaceTexts(data)
				this.setState({ tasks })
			})
		})
	}

	nextQuestion = () => {
		let { flowIndex } = this.state
		flowIndex++
		if (flowIndex >= this.CHALLENGE_FLOW.length) this.props.onExampleUpload(null, false)
		this.setState({ flowIndex })
	}

	handleChallengeAnswer = (challenge, answer) => {
		const user = {
			username: localStorage.getItem('username'),
			email: localStorage.getItem('email')
		  }
		const phase = this.props.location[this.props.extras.skill._id].phase
		userTaskService.answerChallenge(user, challenge, answer, { position: 0, phase }).then(() => {
			if (this.state.flowIndex === 0) {
				let fr = new FileReader();
				fr.onloadend = () => this.setState({ image: fr.result })
				fr.readAsDataURL(answer); 
			}
			this.nextQuestion()
		})
	}

	render() {
		const { t } = this.props;
		const challenge = this.state.tasks[this.state.flowIndex]
		return (
			<div id='exampleupload-component' className='d-flex flex-column'>
				<div className="back" onClick={() => this.props.onExampleUpload(null, false)}>&lt; {t('back')}</div>
				<Stepper
					total={this.CHALLENGE_FLOW.length}
					active={this.state.flowIndex + 1} />
				<Row>
					<Col>
						{
							!!challenge &&
							<ChallengeContainer 
								challenge={challenge}
								onAnswer={this.handleChallengeAnswer}
								sequence={this.props.sequence}
								userLocation={this.props.userLocation} />
						}
					</Col>
					{ !!this.state.image && 
						<Col xs={6} className='image'>
							<img src={this.state.image} alt='Example upload'></img>
						</Col>
					}
				</Row>
			</div>
		)
	}
}

//export default Examples;
export default withTranslation()(ExampleUpload);