import apiService from './api.service';

class SkillService {

    API_ENDPOINT = 'skill'

    getSkills(lang = 'en') {
        let clientLang = localStorage.getItem('lang')
        if (!!clientLang) lang = clientLang
        return apiService.get(`${this.API_ENDPOINT}/lang/${lang}`)
            // .then(res => {
            //     let data = res.data.translatedskills
            //     return data.map(skill => skill.title)
            // })
            .then( res => res.data.translatedskills )
    }

    getSkill(skill, lang = 'en') {
        let clientLang = localStorage.getItem('lang')
        if (!!clientLang) lang = clientLang
        return apiService.get(`${this.API_ENDPOINT}/${skill}/lang/${lang}`)
            .then(res => res.data.translatedSkill)
    }

}

export default new SkillService();

