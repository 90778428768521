import React, { createElement } from 'react';
import { withTranslation } from 'react-i18next';

import userTaskService from '../../../services/usertask.service';
import userProfileService from '../../../services/userprofile.service';
import userService from '../../../services/user.service';
import taskService from '../../../services/task.service';

import ApproachParallel from '../approachparallel';
import ApproachAtEnd from '../approachatend';
import ApproachSelfRating from '../approachselfrating';
import ExampleUpload from '../exampleupload';
import RequestBadgeMessage from '../../modal/requestbadgemessage';

import '../../../styles/dynamiccontent.scss';
import { ImSpinner2 } from 'react-icons/im';
/**********************************************************
File          : landingcontent.js
Classes       : LandingContent
Responsibility: This Component class is responsible for 
collecting content from content service or static and present 
to the main screen(contentmain).
Interacts With: BasicNavigation for TopMenu,
With LandingContent for initial content. 
TODO         : LandingContent will later also load content 
from database.
Organization : BasicNavigation (Top)
               LandingContent (Bottom)
***********************************************************/
 //Class LandingContent
  class DynamicContent extends React.Component {

    constructor(props ) {
      super(props)
      this.state = {
        location: null,
        skills: [],
        tempAnswers: [],
        sequence: null,
        approachMode: 1,
        initialLocation: null,
        language: "en",
        display: '',
        user: null,
        extras: null
      }
      this.changeLanguage=this.changeLanguage.bind(this);
    }

    changeLanguage = (lng) => {
      this.props.i18n.changeLanguage(lng);
      this.setState({language:lng})
    };

    componentDidMount() {
      userService.getUserSequence(localStorage.getItem('username')).then(sequence => this.setState({ sequence }, () => {
        userProfileService.getUserProfile(localStorage.getItem('username')).then( userProfile => {
            this.setState({ location: userProfile.location, approachMode: userProfile.approachtype }, () => this.getAnswers(userProfile.location))
        })
      }))
      userService.getUser(localStorage.getItem('username')).then(user => this.setState({ user }))
      let language = this.props.language
      this.changeLanguage(language);
    }

    

    //deepClone
    deepClone = (location) => {
      let initialLocation= []
      location.forEach( skillLoc => initialLocation.push({skillid: skillLoc.skillid, position: skillLoc.position, positionstart: skillLoc.positionstart}))
      return initialLocation
    }

    /**
     * Get missing answers from the user
     * 
     * This function check user's location for every skill and looks for missing answers. If an answer if missing, it will be retrieve from the API
     * 
     * @param location Current location of user's progress
     */
    getAnswers = (location) => {
      // Getting skillid from the location so we don't have to wait for the skills calls
      let skills = Object.keys(location)
      if (skills!=null)
      {
      skills.forEach(skillid => {
        let skillLocation = location[skillid]
        let sequence = this.state.sequence[skillLocation.phase]
        if (parseInt(skillLocation.position)>1)
        {
        // Looking the sequence to see what steps have a dependson
        sequence.slice((skillLocation.position - 1) < 0 ? 0 : skillLocation.position - 1).filter(step => step.dependson > 0).forEach(step => {
          if (step.dependson <= skillLocation.position) {
            // Checking if the answer is saved already
            if (this.state.tempAnswers.filter(ans => ans.step === step.dependson && ans.challenge.skill._id === skillid).length < 1) {
              // Pulling the answer from the API
              userTaskService.getAnswer(localStorage.getItem('username'), skillid,  skillLocation.phase, step.dependson).then(answerInfo => {
                /* We need to get the task from the API too. 
                   tempAnswer is an Array os objects and each object needs to have:
                      answer (actual answer, like value of tagid),
                      step (step index in the sequence),
                      challenge (task that is displayed to the user),
                      and skillid
                */
                if (answerInfo.answers.length > 0 || !!answerInfo.img) {
                  let answer
                  if (!!answerInfo.img) {
                    let binary = '';
                    const bytes = new Uint8Array(answerInfo.img.data.data);
                    const len = bytes.byteLength;
                    for (let i = 0; i < len; i++) {
                      binary += String.fromCharCode(bytes[i]);
                    }
                    const file = window.btoa(binary);
                    answer = `data:image/png;base64,` + file;
                  } else {
                    try {
                      answer = +answerInfo.answers[0]
                    } catch {
                      answer = answerInfo.answers[0]
                    }
                  }
                  let { taskid, stepid } = answerInfo;
                  let language = this.props.language
                  taskService.getTaskTranslated(taskid,language).then(challenge => {
                       this.state.tempAnswers.push({ step: stepid, answer, challenge, skillid: +skillid});
                  })
                }
              })
              .catch(err => console.error(err))
            }
          }
        })
      }
      })
    }
    }

    handleChallengeAnswer = (challenge, answer, cb, step = null) => {
      const user = {
        username: localStorage.getItem('username'),
        email: localStorage.getItem('email')
      }
      let location = this.state.location
      if (this.state.approachMode === 1 || this.state.approachMode === 3) {  //Abhi: In case of approach1 only after answering challenge location is updated
        location =this.increasePosition(challenge.skill._id)
      }
      let dependsOnStep = -1
      if (!!step) {
        dependsOnStep = this.state.sequence[location[challenge.skill._id].phase].map(step => step.dependson).indexOf(step.sequence)
      } else {
        dependsOnStep = this.state.sequence[location[challenge.skill._id].phase].map(step => step.dependson).indexOf(location[challenge.skill._id].position)
      }
      
      let { tempAnswers } = this.state
      if (dependsOnStep > -1) {
        if (challenge.type === 5) {
          let fr = new FileReader();
          fr.onload = e => tempAnswers.unshift({ step: dependsOnStep, answer: e.target.result, challenge, skillid: +challenge.skill._id})
          fr.readAsDataURL(answer)
        } else {
          tempAnswers.unshift({ step: dependsOnStep, answer, challenge, skillid: +challenge.skill._id})
        }
        this.setState({ tempAnswers })
      }
      
      Promise.all([
        userTaskService.answerChallenge(user, challenge, answer, this.state.location[challenge.skill._id]),
        userProfileService.updateUserProfile(challenge.skill._id, location[challenge.skill._id].phase, location[challenge.skill._id].position,location[challenge.skill._id].position)
      ]).then(() => {
        //Update Profile called
        //console.log('handleChallengeAnswer:- updateUserProfile- Position:',location[challenge.skill._id].position)
        //Need to update initialPositiontoReflect the change.
        if (this.isEndOfLevel(challenge.skill._id, challenge._id, this.state.sequence)) {
          userService.userPhaseComplete(localStorage.getItem('username'), location[challenge.skill._id].phase - 1, challenge.skill._id)
          let { tempAnswers } = this.state
          tempAnswers = tempAnswers.filter(answer => answer.skillid !== challenge.skill._id)
          this.setState({ tempAnswers }, () => this.props.updateModalContent(<RequestBadgeMessage level={location[challenge.skill._id].phase - 1} skill={challenge.skill} updateModalContent={this.props.updateModalContent} language={this.props.language}/>))
        }
        cb()
      })
    }

    increasePosition = skillid => {
      let tempLocation = this.state.location
      ++tempLocation[skillid].position
      
      if (tempLocation[skillid].position >= this.state.sequence[tempLocation[skillid].phase].length) {
        if (tempLocation[skillid].phase < Object.entries(this.state.sequence[tempLocation[skillid].phase]).length) {
          ++tempLocation[skillid].phase
        }
        tempLocation[skillid].position = 0
      }
      return tempLocation
    }

    //Newly added Abhi - 5th Aug 2021
    setPosition = (skillid,newPosition,updatedPhase) => {
      let tempLocation = this.state.location
        tempLocation[skillid].position =newPosition
        tempLocation[skillid].phase =updatedPhase
      return tempLocation
    }
  
    replaceTexts = (challenge, step) => {

      let skillText = this.props.t (challenge.skill.title.toLowerCase());

      // Need to fix the grammar of the skill title.
      // This is a complete hack. In the future we should come up with a more generic solution
      // or alternatively a service within the app where all of these customisations can be 
      // centrally managed.

      // Special case to only handle self rating question (task id: 8)
      // Special case to only handle example upload questions (task ids 15-17)
      if( challenge._id === 8 || (challenge._id > 14 && challenge._id < 18) ) {

        skillText = skillText.replace('Συνεργασία', 'Συνεργασίας')
                             .replace('Δημιουργικότητα', 'Δημιουργικότητας')
                             .replace('Επίλυση προβλήματος', 'Επίλυσης προβλημάτων')
                             .replace('Κοινωνικές δεξιότητες', 'Κοινωνικών δεξιοτήτων')
                             .replace('Επικοινωνία', 'Επικοινωνίας');
      }

      // Special case to handle free text question (level 4, task 10b, task id: 125)
      if( challenge._id === 125 ) {
        skillText = skillText.replace('Συνεργασία', 'Συνεργασίας')
                             .replace('Δημιουργικότητα', 'Δημιουργικότητας')
                             .replace('Επίλυση προβλήματος', 'Επίλυσης Προβλημάτων')
                             .replace('τη δεξιότητα της Κοινωνικές δεξιότητες', 'τις Κοινωνικές Δεξιότητες')
                             .replace('Επικοινωνία', 'Επικοινωνίας')
      }

      challenge.title = challenge.title.replace('[Skill_Tagged]', skillText )

      // Special case to only handle self rating question (task id: 11, 54, 136, 182)
      // 'How would you assess yourself as a [Skill] person today from 
      // Need to do a little more to this one so we will modify the challenge title instead
      if( challenge._id === 11 || challenge._id === 54 || challenge._id === 136 || challenge._id === 182 ) {

        challenge.title = challenge.title.replace('Συνεργασία', 'Συνεργάτη')
                                          .replace('Δημιουργικότητα', 'Δημιουργικό άτομο')
                                          .replace('ως Επίλυση προβλήματος', 'στην Επίλυση Προβλημάτων ')
                                          .replace('Κοινωνικές δεξιότητες', 'άτομο με Κοινωνικές Δεξιότητες')
                                          .replace('Επικοινωνία', 'Επικοινωνιακό πρόσωπο');
      }

      if (!!challenge.body) {

        // Get correctly translated skill title
        // As a default we will retrieve the English language version first
        let skill = challenge.skill.title;
        
        if(this.props.language !== 'en' ) {
          // Not in English, we need to retrieve the correct translation
          let translation = challenge.skill.translations.find( trans => {
            return trans.langcode === this.props.language;
          })

          skill = translation.title;
        }

        if (Array.isArray(challenge.body)) challenge.body.forEach(option => option.tag = option.tag.replace('[Skill_Tagged]', skill))
        else challenge.body = challenge.body.replace('[Skill_Tagged]', skill)
      }
      if (!!step && step.dependson > 0) {
        let tempAnswerIndex = this.state.tempAnswers.findIndex(answer => answer.skillid === challenge.skill._id && answer.step === step.dependson)
        if (tempAnswerIndex > -1) {
          let replacement = this.state.tempAnswers[tempAnswerIndex]
          let tempText = ''
          switch (replacement.challenge.type) {
            case 1:
              let answerIndex = replacement.challenge.body.map(answer => answer.tagid).indexOf(replacement.answer)
              tempText = replacement.challenge.body[answerIndex].tag
              break
            case 3:
            case 4:
              tempText = replacement.answer
              break
            default: break;
            }
          challenge.title = challenge.title.replace('[Challenge2_Selection]', tempText)
          challenge.title = challenge.title.replace('[Dimension_Selected]', tempText)
          challenge.title = challenge.title.replace('[Dimension_Selection]', tempText)
          challenge.title = challenge.title.replace('[Rating_Selected]', tempText)
          challenge.title = challenge.title.replace('[Outcome_Selected]', '')
        }
      }
      if (!!this.state.user) challenge.title = challenge.title.replace('[Student_Name]', this.state.user.firstname)
      return challenge
    }
  
    loadChallenge = (skill, phase, position, cb) => {
      let step
      let currentPhase
      if (phase === null || phase === undefined || position === null || position === undefined) {
        let location = this.state.location[skill._id]
        step = this.state.sequence[location.phase][location.position]
      }
      else {
        step = this.state.sequence[phase][position]
      }
      if (!step || step.type === 1) return cb(null)
      let taskToBeLoaded = -1
      let language= this.props.language
      if (Array.isArray(step.taskref)) {
        for (let i = 0; i < step.taskref.length; i++) {
          if (step.taskref[i].skillid === skill._id) {
            taskToBeLoaded = step.taskref[i].taskid; break;
          }
        }
        if (step.taskref.length===0) { //Case of condition based task based on previous step answer
            //Get answer from previous step 
            const previousAnswer = this.state.tempAnswers.filter(answers => answers.step.phase ===currentPhase);
            let answer = this.state.tempAnswers[previousAnswer]
            const conditionsForSkill= step.conditions.filter(conditions=>conditions.skillid===skill._id )
            const valueToCheck= conditionsForSkill[0].selection
            if (parseInt(answer)>=valueToCheck) {
              step.taskref=conditionsForSkill[0].taskid
              taskToBeLoaded = step.taskref
            } else {
              step.taskref=conditionsForSkill[1].taskid
              taskToBeLoaded = step.taskref
            }
        }
      }
      else {
        taskToBeLoaded = step.taskref
      }
      if (!!step.conditions) {
        let answerIndex = this.state.tempAnswers.map(ans => ans.step).indexOf(step.dependson)
        if (answerIndex > -1) {
          let answer = this.state.tempAnswers[answerIndex]
          if (!!skill && !!answer) {
            let tempStep = []
            if (answer.challenge.type === 4) {
              if (answer.answer === 10) {
                tempStep = step.conditions.filter(ans => ans.skillid === skill._id).filter(ans => ans.selection === 10)
              } else {
                tempStep = step.conditions.filter(ans => ans.skillid === skill._id).filter(ans => ans.selection !== 10)
              }
            } else {
              tempStep = step.conditions.filter(ans => ans.skillid === skill._id).filter(ans => ans.selection === answer.answer)
            }
            if (tempStep.length > 0) {
              taskToBeLoaded = tempStep[0].taskid
            }
          }
        }
      }
      if (taskToBeLoaded <= 0) return cb(null)
      taskService.getTaskTranslated(taskToBeLoaded,language).then(challenge => {
        challenge.skill = skill
        this.replaceTexts(challenge, step)
        cb(challenge)
      })
    }
  
    isEndOfLevel = (skillid, challengeid, sequence) => {
      let { location } = this.state
      let { phase, position } = location[skillid]
      if (this.state.approachMode === 1 || this.state.approachMode === 3) return position === 0 && phase > 1
      else {
        const prevSequence = sequence[phase - 1]
        if (!!prevSequence) {
          const lastStep = sequence[phase - 1].slice().pop()
          if (lastStep.taskref === challengeid) return true
        }
      }
      return false
    }

    getScreen = (approachMode = this.state.approachMode) => {
      if (this.state.display === 'upload') return ExampleUpload
      switch(approachMode) {
        case 3: return ApproachSelfRating
        case 2: return ApproachAtEnd
        case 1: default: return ApproachParallel
      }
    }

    handleExampleUpload = (skill = null, showUpload = true) => this.setState({
      display: showUpload ? 'upload' : '',
      extras: !!skill ? { skill } : null
    })

    //Updates the location to the state
    updateLocation = location => this.setState({ location })

    //Get the Step from the sequence array 
    //Note the array position starts from 0.
    //But as for steps, there is no step with index 0
    getCurrentStep = (skillid, phase = null, position = null) => {
      const location = this.state.location[skillid]
      if (phase === null) {
        phase = location.phase
      }
      if (position === null) {
        position = location.position
      }
      return this.state.sequence[phase][position]
    }

    getSequenceParent = (skillid, phase = null) => {
      const location = this.state.location[skillid]
      if (phase === null) {
        phase = location.phase
      }
      let sequence = {}
      sequence = this.state.sequence[phase]
      return sequence
    }

    render() {
      let language = this.props.language
      // check if any of these are null
      if  ( this.state.sequence != null && this.state.location !=null && this.props!=null )
      { 
        return createElement(this.getScreen(), {
          ...this.props,
          sequence: this.state.sequence,
          location: this.state.location,
          language: language,
          tempAnswers: this.state.tempAnswers,
          increasePosition: this.increasePosition,
          setPosition: this.setPosition,
          loadChallenge: this.loadChallenge,
          onChallengeAnswer: this.handleChallengeAnswer,
          getCurrentStep: this.getCurrentStep,
          updateLocation: this.updateLocation,
          getSequenceParent: this.getSequenceParent,
          onExampleUpload: this.handleExampleUpload,
          replaceTexts: challenge => this.replaceTexts(challenge, null),
          extras: this.state.extras
        })
      } else {
        return (<div className='loading...'>
            <ImSpinner2 className='icon-spin' /> <br/>
              Initialization of User Profile ongoing...<br/>
                           
        </div>)
      }
    }
  }

//export default DynamicContent;
export default withTranslation()(DynamicContent);