import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

import examplesService from '../../services/examples.service';

import SkillMenu from './skillmenu';

import '../../styles/examples.scss';
import Collaboration1 from '../../assets/img/collaboration1.png'
import Collaboration2 from '../../assets/img/collaboration2.png'
import Creativity1 from '../../assets/img/creativity1.png'
import Creativity2 from '../../assets/img/creativity2.png'
import Problem1 from '../../assets/img/problem1.png'
import Problem2 from '../../assets/img/problem2.png'
import Social1 from '../../assets/img/social1.png'
import Social2 from '../../assets/img/social2.png'
import Communication1 from '../../assets/img/communication1.png'
import Communication2 from '../../assets/img/communication2.png'
import EmptyExamples from '../../assets/img/examples.png'

class Examples extends Component {

	constructor(props) {
		super(props)
		this.state = {
			examples: {},
			selectedSkill: -1,
			selectedStep: -1
		}
		let language = this.props.language
		this.changeLanguage(language);
	}

	changeLanguage = (lng) => {
		this.props.i18n.changeLanguage(lng);
	};

	componentDidMount() {
		let language = this.props.language
		this.props.skills.forEach(skill => {
			examplesService.getExampleTranslated(skill._id,language).then(data => {
				const { examples } = this.state
				examples[data._id] = data
				this.setState({ examples })
			})
		})
	}

	skillExist = (skillID = this.state.selectedSkill) => !!this.state.examples[skillID]
	stepExist = (stepIndex = this.state.selectedStep) => {
		if (this.skillExist()) {
			return !!this.state.examples[this.state.selectedSkill].examples[stepIndex]
		}
		return false
	}

	changeExample = (skillid) => this.setState({ selectedSkill: this.skillExist(skillid) ? skillid : -1, selectedStep: 0 })
	changeStep = (stepIndex) => this.setState({ selectedStep: this.stepExist(stepIndex) ? stepIndex : 0})

	previousStep = () => this.changeStep(this.state.selectedStep - 1)
	nextStep = () => {
		const step =  this.state.selectedStep + 1
		this.changeStep(this.stepExist(step) ? step : this.state.examples[this.state.selectedSkill].examples.length - 1)
	}

	getImage = (filename = '') => {
		switch (filename) {
			case 'Collaboration1': return Collaboration1
			case 'Collaboration2': return Collaboration2
			case 'Creativity1': return Creativity1
			case 'Creativity2': return Creativity2
			case 'Problem1': return Problem1
			case 'Problem2': return Problem2
			case 'Social1': return Social1
			case 'Social2': return Social2
			case 'Communication1': return Communication1
			case 'Communication2': default: return Communication2
		}
	}

	render() {
		const example = this.state.examples[this.state.selectedSkill]
		const step = this.skillExist() ? example.examples[this.state.selectedStep] : null
		let {t} = this.props
		return (
			<div id='examples-component' className='d-flex flex-row'>
				<SkillMenu skills={this.props.skills} language={this.props.language}  activeSkill={this.state.selectedSkill} onSkillSelected={this.changeExample} />
				<div className="content">
					{
						(this.skillExist() && this.stepExist()) ?
						<div className="example container-fluid row">
							<div className="col-5">
								<div className="text box-shadow">{step.text}</div>
							</div>
							<div className="img col-7">
								<img src={this.getImage(step.image)} alt="Example" />
							</div>
							<Accordion>
								<Card>
									<Accordion.Toggle className='d-flex justify-content-between' as={Card.Header} variant="link" eventKey="0">
										<div className="title">{t("definition")}</div>
										<div className="arrow"><IoIosArrowDown /></div>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="0">
										<Card.Body>{example.definition}</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
							{
								example.examples.length < 2 ? '' :
								<div className="steps d-flex flex-row justify-content-center">
									<div className="step previous" onClick={this.previousStep}><IoIosArrowBack /></div>
									{
										example.examples.map((step, index) =>
											<div className={`step ${this.state.selectedStep === index ? 'active' : ''}`} key={index} onClick={() => this.changeStep(index)}>{index + 1}</div>
										)
									}
									<div className="step next" onClick={this.nextStep}><IoIosArrowForward /></div>
								</div>
							}
						</div>
						:
						<div className="empty d-flex flex-column justify-content-center">
							<img src={EmptyExamples} alt="Empty examples" />
							<div className="text">
								{t('choosea21stcenturyexamples')}
							</div>
						</div>
					}
				</div>
			</div>
		)
	}
}

//export default Examples;
export default withTranslation()(Examples);