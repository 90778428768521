import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';

import Stepper from '../stepper';
import ChallengeContainer from '../challenges/challengecontainer';
import UserProgressMain from './userprogressmain';
import TaggingMessage from '../modal/taggingmessage';

import userProfileService from '../../services/userprofile.service';
import userTaggingService from '../../services/tagging.service';

import '../../styles/approachparallel.scss';

class ApproachParallel extends Component {

	constructor(props) {
		super(props)
		this.state = {
			challenge: null
		}
		let language = this.props.language
		this.changeLanguage(language);
	
	}

	componentDidMount() {
		const { tempState } = this.props
		if (!!tempState) this.setState(tempState)
	}

    componentWillUnmount() {
		this.props.saveTempState(this.state)
	  }

	changeLanguage = (lng) => {
		this.props.i18n.changeLanguage(lng);
	  };

	handleProgress = (skill) => {
		let language = this.props.language
		const currentStep = this.props.sequence[this.props.location[skill._id].phase][this.props.location[skill._id].position]
        if (!!currentStep && currentStep.type === 1) {
          let location = this.props.increasePosition(skill._id)
		  userProfileService.updateUserProfile(skill._id, location[skill._id].phase, location[skill._id].position)
            .then(() => {
				this.props.updateModalContent(<TaggingMessage skill={skill} language={language} updateModalContent={this.props.updateModalContent} />)
				this.props.updateLocation(location)
				userTaggingService.tagSkill(skill)
			})
            .catch(err => console.error('handleProgress',err))
        } 
        else {
			this.props.loadChallenge(skill, null, null, challenge => this.setState({ challenge }))
        }
	}

	handleChallengeAnswer = (challenge, answer) => {
		this.props.onChallengeAnswer(challenge, answer, () => {
			const location = this.props.location[challenge.skill._id]
			const nextStep = this.props.getCurrentStep(challenge.skill._id, location.phase, location.position)
			if (!!nextStep && nextStep.type !== 1) {
				this.props.loadChallenge(challenge.skill, null, null, challenge => this.setState({ challenge }))
			} else this.setState({ challenge: null })
		})
	}

	abortChallenge = () => this.setState({ challenge: null })

	render() {
		let { location, sequence, tempAnswers } = this.props
		let { challenge } = this.state
		let currentStep = null
		let shortSequence = []
		let shortSequenceIndex = -1
		let answerIndex = -1
		if (!!location && !!sequence && !!challenge) {
			let currentLocation = location[challenge.skill._id]
			let currentStepIndex = sequence[currentLocation.phase].map(step => step.sequence).indexOf(currentLocation.position) + 1
			if (currentStepIndex > 0 && sequence[currentLocation.phase][currentStepIndex].type === 3) {
			  currentStep = sequence[currentLocation.phase][currentStepIndex]
			  const shortSequenceBegin = sequence[currentLocation.phase]
			  	.slice(0, currentLocation.position + 1)
				.sort((a, b) => b.sequence - a.sequence)
			  const shortSequenceBeginIndex = shortSequenceBegin.length - 1 - shortSequenceBegin.findIndex(step => step.type !== 3) + 1
			  const shortSequenceEnd = sequence[currentLocation.phase]
				.slice(currentLocation.position + 1)
			  const shortSequenceEndIndex = shortSequenceBegin.length + shortSequenceEnd.findIndex(step => step.type !== 3)
			  shortSequence = sequence[currentLocation.phase].sort((a, b) => a.sequence - b.sequence).slice(shortSequenceBeginIndex, shortSequenceEndIndex)
			  shortSequenceIndex = shortSequence.map(step => step.sequence).indexOf(currentLocation.position) + 1
			  if (currentStep.dependson > 0) {
				answerIndex = tempAnswers.findIndex(answer => answer.step === currentStep.dependson && answer.skillid === challenge.skill._id)
			  }
			}
		}
		return (
		  <div id='approachparallel-component' className="container">
			{ !challenge ? 
					<UserProgressMain
					skills={this.props.skills}
					onSkillTagged={this.handleProgress}
					onExampleUpload={this.props.onExampleUpload}
					location={location}
					sequence={this.props.sequence}
					withProgress={true} />
				:
				<>
					{ !!currentStep && currentStep.type === 3 &&
					<Row>
						<Stepper
							total={shortSequence.length}
							active={shortSequenceIndex + 1} />
					</Row>
					}
					<Row>
						<Col>
							<ChallengeContainer
							challenge={challenge}
							onAnswer={this.handleChallengeAnswer}
							sequence={this.props.sequence}
							userLocation2={this.props.location[challenge.skill._id]}
							userLocation={this.props.userLocation}
							abortChallenge={(!!challenge && challenge.type === 5) ? this.abortChallenge : false}/>
						</Col>
						{ !!currentStep && currentStep.type === 3 && currentStep.dependson > 0 && 
							<Col xs={6} className='image'>
							{ !!this.props.tempAnswers[answerIndex] ? 
								<img src={this.props.tempAnswers[answerIndex].answer} alt='Example answer'></img>
								:
								<FaSpinner className='icon-spin'/>
							}
							</Col>
						}
					</Row>
				</>
			}
		  </div>
		);
	}
}

//export default ApproachParallel
export default  withTranslation()(ApproachParallel);