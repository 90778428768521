import apiService from "./api.service";

class DefinitionsService {

	API_ENDPOINT = 'onboarding'

	getDefinition(skillid) {
		return apiService.get(`${this.API_ENDPOINT}/${skillid}`)
			.then(res => res.data.onboarding)
	}
	getDefinitionTranslated(skillid,langid) {
		return apiService.get(`${this.API_ENDPOINT}/${skillid}/${langid}`)
			.then(res => res.data.onboarding)
	}

}

export default new DefinitionsService();