import React, { Component } from 'react'
import { Container } from 'react-bootstrap';

import '../../styles/singleoptionchallenge.scss'

class SingleOptionChallenge extends Component {

    render() {
        return (
            <Container id='singleoptionchallenge-component'>
                { this.props.challenge.body.map( option => 
                        <label className='answer d-flex flex-row align-items-center' key={ `${this.props.challenge._id}-${this.props.challenge.skill._id}-${option.tagid}` }>
                            <input type="radio" name='radio' onChange={() => this.props.onAnswer(option)} />
                            <span className='mark'></span>
                            <span className='text'>{ option.tag }</span>
                        </label>
                    )}
            </Container>
        )
    }
}

export default SingleOptionChallenge;