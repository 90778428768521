import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css'
import reportWebVitals from './reportWebVitals';
//Language support library i18n 
import './i18n';
//Added to support as Progressive Web Application
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


ReactDOM.render(
  
    <Suspense fallback={<div>Loading...</div>}>
    <App />
    </Suspense>
 ,
  document.getElementById('root')
  
); serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
