import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Table } from 'react-bootstrap';

import '../../styles/frequencychallenge.scss';

class FrequencyChallenge extends Component {

    constructor() {
        super()
        this.state = {
            answers: {}
        }
    }

    handleChange = e => {
        const { name: statementID, value: answerValue } = e.target
        if (!!statementID && !!answerValue) {
            const { answers }  = this.state
            answers[statementID] = answerValue
            this.setState({ answers }, () => {
                if (Object.entries(this.state.answers).length === this.props.challenge.body.length) {
                    this.props.onAnswer(Object.values(this.state.answers))
                    this.setState({ answers: {} })
                }
            })
        }
    }

    render() {
        let {t}= this.props
        const { challenge } = this.props
        return (
            <Container id='frequencychallenge-component'>
                <Table striped borderedless='true' hover>
                    <thead>
                        <tr>
                            <th>{t('learningoutcomes')}</th>
                            {
                                challenge.answers.map((answer, index) => <th key={`header-${index}`}>{answer}</th> )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            challenge.body.map(statement =>
                                <tr key={`${challenge._id}-statement-${statement.tagid}`} className='statement'>
                                    <td className='text'>{statement.tag}</td>
                                    {
                                        challenge.answers.map((answer, index) => <td key={`statement-${statement.tagid}-value-${index}`} onClick={this.handleChange} className='answers'>
                                            <input type="radio" name={statement.tagid} value={answer} id={`statement-${statement.tagid}-value-${index}`} />
                                            <label htmlFor={`statement-${statement.tagid}-value-${index}`} className='checkbox'></label>
                                        </td> )
                                    }
                                </tr>)
                        }
                    </tbody>
                </Table>
            </Container>
        )
    }
}

export default withTranslation()(FrequencyChallenge);