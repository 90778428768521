import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import InputRange from 'react-input-range';
import { FaSpinner } from 'react-icons/fa';

import userProfileService from '../../services/userprofile.service';
import userTaggingService from '../../services/tagging.service';

import ChallengeSummary from './challengesummary'
import Stepper from '../stepper'
import ChallengeContainer from '../challenges/challengecontainer';
import UserProgressMain from './userprogressmain';

import '../../styles/approachselfrating.scss';
import IntroImage from '../../assets/img/approachselfrating.png';

class ApproachSelfRating extends Component {

	TAG_MULTIPLIER = 1

	ANSWER_OPTIONS = [
		'Not at all',
		'A little',
		'Sometimes',
		'A lot',
		'All the time'
	]

	constructor(props) {
		super(props)
		this.state = {
			location: '',
			tags: {},
			taggingIndex: 0,
			taggingValue: 0,
			challenges: {},
			challenge: null,
			tempLocation: null
		}
	}

	componentDidMount() {
		const { tempState } = this.props
		if (!!tempState) this.setState(tempState)
		else this.saveInitialLocation()
	}

    componentWillUnmount() {
		this.props.saveTempState(this.state)
	  }

	handleTaggingNextSkill = () => {
		const { tags, taggingIndex, taggingValue } = this.state
		const { skills } = this.props
		const skill = Object.values(skills)[taggingIndex]
		tags[skill._id] = taggingValue
		if (taggingIndex >= Object.keys(skills).length - 1) {
			const { skills, sequence } = this.props
			const { tags, challenges } = this.state
			skills.forEach(skill => {
				if (tags[skill._id] > 0) {
					let tagAmmount = Math.trunc(tags[skill._id] * this.TAG_MULTIPLIER)
					const skillLocation = this.props.location[skill._id]
					let finalStep = sequence[skillLocation.phase][skillLocation.position + tagAmmount]
					if (!!finalStep) {
						while (finalStep.type !== 1) {
							if (!!sequence[skillLocation.phase][skillLocation.position + tagAmmount + 1]) {
								tagAmmount++
								finalStep = sequence[skillLocation.phase][skillLocation.position + tagAmmount]
								if ((skillLocation.position + tagAmmount) === sequence[skillLocation.phase].length - 1) break
							} else break
						}
					} else tagAmmount = sequence[skillLocation.phase].length - 1 - skillLocation.position
					let shortSequence = sequence[skillLocation.phase].slice(skillLocation.position, skillLocation.position + tagAmmount + 1)
					challenges[skill._id] = shortSequence
				}
			})
			this.setState({ tags, location: 'summary', challenges })
		} else {
			const nextSkill = Object.values(skills)[taggingIndex + 1]
			const nextValue = !!nextSkill ? tags[nextSkill._id] || 0 : 0
			this.setState({ tags, taggingIndex: taggingIndex + 1, taggingValue: nextValue })
		}
	}

	handleTaggingPreviousSkill = () => {
		const { tags, taggingIndex } = this.state
		if (taggingIndex <= 0) return
		const { skills } = this.props
		const previousSkill = Object.values(skills)[taggingIndex - 1]
		const previousValue = !!previousSkill ? tags[previousSkill._id] || 0 : 0
		this.setState({ taggingIndex: taggingIndex - 1, taggingValue: previousValue })
	}

	loadNextChallenge = () => {
		const step = this.getNextStep()
		if (step === null) this.setState({ location: 'progressSummary' })
		else {
			const skill = this.props.skills[this.props.skills.map(skill => skill._id).indexOf(step.skillid)]
			if (step.type === 1) {
				userTaggingService.tagSkill(skill)
				this.increaseLocation(skill._id).then(() => {
					let areChallengesLeft = this.removeFirstStep()
					if (!!areChallengesLeft) this.loadNextChallenge()
					else this.setState({ location: 'progressSummary' })
				})
			} else {
				this.props.loadChallenge(skill, step.phase, step.sequence - 1, challenge => {
					if (!!challenge) {
						challenge.skill = this.props.skills[this.props.skills.map(skill => skill._id).indexOf(step.skillid)]
						this.setState({ location: 'challenges', challenge })
					}
				})
			}
		}
	}

	resetApproach = () => {
		this.setState({
			location: 'tagging',
			challenge: null,
			tags: {},
			taggingIndex: 0,
			taggingValue: 0,
			challenges: {}
		})
	}

	getNextStep = () => {
		const { challenges } = this.state
		if (challenges === {} || challenges === null) return null
		let skills = Object.keys(challenges)
		if (skills.length > 0) {
			let skillChallenges = challenges[skills[0]]
			if (skillChallenges.length > 0) {
				skillChallenges[0].skillid = +skills[0]
				return skillChallenges[0]
			} else {
				delete challenges[skills[0]]
				this.setState({ challenges })
				return this.getNextStep()
			}
		} else return null
	}

	removeFirstStep = () => {
		// Returns boolean if there are challenges left
		const { challenges } = this.state
		if (challenges === {} || challenges === null) return false
		let skills = Object.keys(challenges)
		if (skills.length > 0) {
			let skillChallenges = challenges[skills[0]]
			if (skillChallenges.length > 0) {
				skillChallenges = skillChallenges.slice(1)
				if (skillChallenges.length > 0) {
					challenges[skills[0]] = skillChallenges
				} else {
					delete challenges[skills[0]]
				}
				this.setState({ challenges })
				return true
			} else {
				delete challenges[skills[0]]
				this.setState({ challenges })
				return this.removeFirstStep()
			}
		} else return false

	}

	increaseLocation = (skillid) => {
		const location = this.props.increasePosition(skillid)
		return userProfileService.updateUserProfile(skillid, location[skillid].phase, location[skillid].position).then(
			() => this.props.updateLocation(location)
		)
	}

	handleChallengeAnswer = (challenge, answer) => {
		const step = this.getNextStep()
		this.props.onChallengeAnswer(challenge, answer, () => {
			const areChallengesLeft = this.removeFirstStep()
			if (!!areChallengesLeft) this.loadNextChallenge()
			else this.setState({ location: 'progressSummary' })
		}, step)
	}

	saveInitialLocation = () => this.setState({ tempLocation: JSON.parse(JSON.stringify(this.props.location)) })

	getContent = () => {
		let { t } = this.props
		switch (this.state.location) {
			case 'summary':
				return <ChallengeSummary
						skills={this.props.skills.map(skill => {
							const challenges = this.state.challenges[skill._id]
							skill.challenges = !!challenges ? challenges.filter(step => step.type !== 1) : []
							return skill
						})}
						goBack={() => this.setState({ location: 'tagging' })}
						goNext={this.loadNextChallenge}
						/>
			case 'challenges':
				const currentStep = this.getNextStep()
				const { sequence, tempAnswers } = this.props
				let { challenge } = this.state
				let shortSequence = []
				let shortSequenceIndex = -1
				let answerIndex = -1
				if (!!currentStep && !!sequence) {
					const currentSequence = sequence[currentStep.phase]
					const currentPosition = currentStep.sequence - 1
					const shortSequenceBegin = currentSequence.slice(0, currentPosition + 1).sort((a, b) => b.sequence - a.sequence)
					const shortSequenceBeginIndex = shortSequenceBegin.length - 1 - shortSequenceBegin.findIndex(step => step.type !== 3) + 1
					const shortSequenceEnd = currentSequence.slice(currentPosition + 1)
					const shortSequenceEndIndex = shortSequenceBegin.length + shortSequenceEnd.findIndex(step => step.type !== 3) - 1
					shortSequence = currentSequence.sort((a, b) => a.sequence - b.sequence).slice(shortSequenceBeginIndex, shortSequenceEndIndex + 1)
					shortSequenceIndex = shortSequence.map(step => step.sequence).indexOf(currentStep.sequence)
					if (shortSequenceIndex === -1) shortSequenceIndex = shortSequence.length - 1
					if (currentStep.dependson > 0) {
						answerIndex = tempAnswers.findIndex(answer => answer.step === currentStep.dependson && answer.skillid === challenge.skill._id)
					}
				}
				return (
					<>
						{ !!currentStep && currentStep.type === 3 &&
						<Row>
							<Stepper
							total={shortSequence.length}
							active={shortSequenceIndex + 1} />
						</Row>
						}
						<Row>
						<Col>
							<ChallengeContainer
							challenge={this.state.challenge}
							onAnswer={this.handleChallengeAnswer}
							sequence={this.props.sequence}
							userLocation2={this.props.location[this.state.challenge.skill._id]}
							userLocation={this.props.userLocation} />
						</Col>
						{ !!currentStep && currentStep.type === 3 && currentStep.dependson > 0 && 
							<Col xs={6} className='image'>
							{ !!this.props.tempAnswers[answerIndex] ? 
								<img src={this.props.tempAnswers[answerIndex].answer} alt='Example answer'></img>
								:
								<FaSpinner className='icon-spin'/>
							}
							</Col>
						}
						</Row>
					</>
				)
			case 'progressSummary':
				return (
					<div className='d-flex flex-column'>
						<div className="row">
							<UserProgressMain
							skills={this.props.skills}
							onSkillTagged={this.handleProgress}
							location={this.props.location}
							initialLocation={this.state.tempLocation}
							sequence={this.props.sequence}
							withProgress={true}
							reduced={true}/>
						</div>
						<div className="primary-button row align-self-end start-again" onClick={() => {this.saveInitialLocation(); this.resetApproach()}}>Start again</div>
					</div>
				)
			case 'tagging': default:
				const threshhold = this.state.taggingIndex / (this.props.skills.length - 1) * 100
				return <div className="tagging">
					<div className="intro box-shadow d-flex flex-row justify-content-between">
						<div className="img align-self-end"><img src={IntroImage} alt="Intro description" /></div>
						<div className="text align-self-center">{t("rateskills")}</div>
					</div>
					<div className="progressbar d-flex flex-row justify-content-between">
						<div className="horizontal-bar" style={{ backgroundImage: `linear-gradient(to right, orange ${ threshhold }%, grey ${threshhold + 1}%)`}}/>
						{ this.props.skills.map((skill, index) => <div className={`skill ${index < this.state.taggingIndex ? 'completed' : index === this.state.taggingIndex ? 'selected' : 'disabled' }`} key={skill._id}>
							<div className="mark">
								<div className="text">{t(skill.title.toLowerCase())}</div>
							</div>
						</div>)}
					</div>
					{ !!this.props.skills[this.state.taggingIndex] && <div className="skillname">{ t(this.props.skills[this.state.taggingIndex].title.toLowerCase()) }</div> }
					<div className="slider box-shadow">
						<InputRange
							maxValue={this.ANSWER_OPTIONS.length - 1}
							minValue={0}
							value={this.state.taggingValue}
							onChange={taggingValue => this.setState({ taggingValue })} />
					</div>
					<div className="actions d-flex flex-row justify-content-end">
						<div className="back secondary-button" onClick={this.handleTaggingPreviousSkill}>{t("back")}</div>
						<div className="next primary-button" onClick={this.handleTaggingNextSkill}>{t("next")}</div>
					</div>
				</div>
		}
	}

	render() {
		return <div id='approachselfrating-component' className="container">
			<this.getContent />
		</div>
	}
}

export default  withTranslation()(ApproachSelfRating);