//Router for supporting multiple routes
import {BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { Component } from 'react';

//Custom imports as part of Assessmake21
import Login from './components/screens/login';
import UserContentMain from './components/screens/usercontentmain';
import ProtectedRoute from './components/protectedroute';

import './App.scss';

class App extends Component {

  constructor() {
    super()
    this.state = {
      user: null
    }
  }

  setAppState = (newState, cb = null) => {
    this.setState({newState}, !!cb ? cb : null)
  }

  render() {
    return (
      <Router basename="/" >
        <ProtectedRoute path="/home" exact component={() => <UserContentMain setAppState={this.setAppState} />} />
        <Route path="/login" exact component={() => <Login setAppState={this.setAppState} />}/>
        <Redirect from="*" to="/home" />
      </Router>
    );
  }
}

export default App;
