import React, { Component } from 'react'
import { Container } from 'react-bootstrap';

import '../../styles/multipleoptionchallenge.scss';

class MultipleOptionChallenge extends Component {

    constructor() {
        super()
        this.state = {
            answers: []
        }
    }

    handleSelect = (option, checked) => {
        let answers = this.state.answers;
        if (checked) answers.push(option)
        else {
            const index = answers.map( answer => answer.tagid).indexOf(option.tagid)
            if (index >= 0) answers.splice(index, 1)
        }
        this.setState({answers}, this.props.onAnswer(answers.length > 0 ? answers : null))
    }

    render() {
        return (
            <Container id='multipleoptionchallenge-component'>
                { this.props.challenge.body.map( option =>
                    <label className='answer col-12 col-md-6' key={ option.tagid }>
                        <input type="checkbox" name='radio' onChange={(e) => this.handleSelect(option, e.target.checked)} />
                        <div className="box d-flex align-items-center">
                            <span className='mark'></span>
                            <span className='text'>{ option.tag }</span>
                        </div>
                    </label>
                )}
            </Container>
        )
    }
}

export default MultipleOptionChallenge;