import React, { Component } from 'react'
import { Container } from 'react-bootstrap';

// import '../../styles/messagechallenge.scss'

class MessageChallenge extends Component {

    componentDidMount() {
        this.props.onAnswer(true)
    }

    render() {
        return (
            <Container id='messagechallenge-component'></Container>
        )
    }
}

export default MessageChallenge;