import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import '../styles/stepper.scss';

class Stepper extends Component {

	render() {
		let content = []
		for (let i = 1; i <= this.props.total; i++) {
			content.push(<div className={"step" + (i <= this.props.active ? ' active' : '') } key={i}>{i}</div>)
		}
		const threshhold = (this.props.active - 1) / (this.props.total - 1) * 100
		return <Container id='stepper-component' className='d-flex flex-col align-items-center'>
			<div className="horizontal-bar" style={{ backgroundImage: `linear-gradient(to right, orange ${ threshhold }%, grey ${threshhold + 1}%)`}}></div>
			<div className="steps d-flex justify-content-between">
				{content}
			</div>
		</Container>
	}
}

export default Stepper