import React, { Component } from 'react'
import { Container } from 'react-bootstrap';

import '../../styles/yesnochallenge.scss'

class YesNoChallenge extends Component {

    render() {
        return (
            <Container id='yesnochallenge-component' className='d-flex flex-row'>
                {
                    this.props.challenge.answers.map(answer => 
                        <div 
                            className={`answer ${ answer === this.props.answer ? 'primary-button' : 'secondary-button'}`} 
                            key={answer} 
                            onClick={() => this.props.onAnswer(answer)}
                        >{answer}</div>
                    )
                }
            </Container>
        )
    }
}

export default YesNoChallenge;