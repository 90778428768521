import Modal from 'react-bootstrap/Modal';
import { Component } from "react";
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import '../../styles/modalcontainer.scss'

class ModalContainer extends Component {

    render() {
        return (
            <Modal id="modalcontainer-component" show={!!this.props.content} >
                <div className="d-flex h-100 justify-content-center align-items-center">
                    {this.props.content}
                </div>
            </Modal>
        )
    }
}

//export default ModalContainer;
export default withTranslation()(ModalContainer);