import React, { Component } from 'react'

class LanguageSelector extends Component {

    LANGUAGES = [
        {
            id: 'en',
            icon: '🇬🇧',
            name: 'English'
        },
        {
            id: 'sv',
            icon: '🇸🇪',
            name: 'Swedish'
        },
        {
            id: 'el',
            icon: '🇬🇷',
            name: 'Greek'
        },
    ]

    handleChange = e => this.props.onChange(e.target.value)

    render() {
        return (<div id="languageselector-component">
            <select onChange={this.handleChange} defaultValue={!!this.props.selected && this.props.selected} >
                { this.LANGUAGES.map( language =>
                    <option key={language.id} value={language.id} >{`${ language.icon } ${ language.name }`}</option>
                )}
            </select>
        </div>)
    }

}

export default LanguageSelector;