import { Component } from "react"
import { withTranslation } from 'react-i18next';

import badgeService from "../../services/badge.service"

import '../../styles/requestbadgemessage.scss'
import BadgeCommunication from '../../assets/img/badgecommunication1.png'

class RequestBadgeMessage extends Component {

    requestBadge = () => {
        badgeService.requestBadge(localStorage.getItem('username'), this.props.skill._id, this.props.level, '')
        this.props.updateModalContent(null)
    }

    render() {
        let {t} = this.props
        return (
            <div id="requestbadgemessage-component" className='d-flex flex-column justify-content-center limited-width'>
                <div className="badge"><img src={BadgeCommunication} alt="Badge" /></div>
                <div className="text">
                    {`${t('badgerequest.congratulations')} ${this.props.level} ${t('badgerequest.in')} ${t(this.props.skill.title.toLowerCase())}`}
                </div>
                <div className="request primary-button" onClick={this.requestBadge}>{t('badgerequest.button')}</div>
                <div className="note">{t('badgerequest.award1')}<br/>{t('badgerequest.award2')}</div>
            </div>
        )
    }
}

export default withTranslation()(RequestBadgeMessage)