import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { FaInfinity } from 'react-icons/fa';

import '../styles/progressmarker.scss';

class ProgressMarker extends Component {

	render() {
		let content = []
		let index = 0;
		for (let i = 1; !!this.props.sequence[i]; i++) {
			const sequenceLength = Object.keys(this.props.sequence).length
			content.push(
				<div className={'level' + (this.props.location.phase >= i ? ' completed' : '') + (this.props.location.phase + 1 < i ? ' disabled' : '')} key={this.props.skill._id + '-' + ++index}>
					{i === sequenceLength ? <FaInfinity /> : i}
				</div>)
			if (this.props.location.phase === i) {
				if (!!this.props.initialLocation) {
					let initialStep = this.props.sequence[i].slice(0, this.props.initialLocation.position).filter(step => step.type !== 1).length - 1;
					let finalStep = this.props.sequence[i].slice(0, this.props.location.position).filter(step => step.type !== 1).length - 1;
					this.props.sequence[i].filter(step => step.type !== 1).map((step, index) =>
						content.push(<div className='checkpoint' key={++index}><div className={'mark' + (initialStep >= index ? ' completed' : '') + (finalStep >= index ? ' progress' : '')} ></div></div>)
					)
				}
				else {
					let currentStep = this.props.sequence[i].slice(0, this.props.location.position).filter(step => step.type !== 1).length - 1;
					this.props.sequence[i].filter(step => step.type !== 1).map((step, index) =>
						content.push(<div className='checkpoint' key={++index}><div className={'mark' + (currentStep >= index ? ' completed' : '')} ></div></div>))
				}
			}
		}
		return (<Container id='progressmarker-component' className='d-flex flex-row justify-content-around align-items-center'>
			{ content }
		</Container>)
	}
}

export default ProgressMarker;