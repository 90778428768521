import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import InputRange from 'react-input-range'
import "react-input-range/lib/css/index.css";

import '../../styles/rangeoptionchallenge.scss'

class RangeOptionChallenge extends Component {

    constructor() {
        super()
        this.state = {
            value: 0,
        }
    }
    
    componentDidMount() {
        this.props.onAnswer(this.state.value)
    }

    render() {
        const { challenge } = this.props
        let description = ''
        let minimum = 0;
        let maximum = 10;

        if(challenge.body.length > 0 ) {
            
            // Update the 'description'
            if(!!challenge.body[this.state.value]) {
                let tag = challenge.body[this.state.value].tag

                // Quick hack to prevent legend being displayed if it doesn't contain a meaningful string
                // Assuming that 'meaningful' is something longer than a few characters.
                if( tag.length > 3 ) {

                    // Need to do something a little hacky and custom as a quick fix
                    // to get the grammar to work in Greek
                    if( this.props.language === 'el') {

                        // Replace names of skills 
                        tag = tag.replace('της Συνεργασία ', 'της Συνεργασίας ')
                                 .replace('της Δημιουργικότητα ', 'της Δημιουργικότητας ')
                                 .replace('της Επίλυση προβλήματος ', 'της Επίλυσης προβλημάτων ')
                                 .replace('της Κοινωνικές δεξιότητες ', ' των Κοινωνικών δεξιοτήτων ')
                                 .replace('της Επικοινωνία ', 'της Επικοινωνίας ');

                        tag = tag.replace('σου Συνεργασία ', 'σου ως Συνεργάτη ')
                                 .replace('σου Δημιουργικότητα ', 'σου ως Δημιουργικό άτομο ')
                                 .replace('σου Επίλυση προβλήματος ', 'σου ως άτομο με Κοινωνικές Δεξιότητες ')
                                 .replace('σου Κοινωνικές δεξιότητες ', 'σου Κοινωνικών δεξιοτήτων ')
                                 .replace('σου Επικοινωνία ', 'σου ως Επικοινωνιακό πρόσωπο ');
                    }

                    description = `${this.state.value}: ${tag}`
                }
            }

            // Get the minimum value for the slider
            minimum = challenge.body[0].tagid;
            maximum = challenge.body[challenge.body.length-1].tagid;
        }

        return (
            <Container id='rangeoptionchallenge-component'>
                <InputRange
                    maxValue={maximum}
                    minValue={minimum}
                    value={ (!this.props.answer || (!!this.props.answer && this.props.answer < 1)) ? minimum : this.props.answer}
                    onChange={value => this.setState({ value }, this.props.onAnswer(value))} />
                <div className="description">{description}</div>
            </Container>
        )
    }
}

export default RangeOptionChallenge