import apiService from './api.service';

class UserTaskService {

    API_ENDPOINT = 'user/task'

    answerChallenge(user, challenge, answers, location) {
        if (challenge.type === 5) return this.answerChallengeWithFile(user, challenge, answers, location)
        return apiService.post(`${this.API_ENDPOINT}/noimg`, {
            username: user.username,
            skillid: challenge.skill._id,
            taskid: challenge._id,
            stepid: location.position,
            phase: location.phase,
            answers
        })
    }

    answerChallengeWithFile(user, challenge, file, location) {
        let formData = new FormData()
        formData.append('image', file)
        formData.append('username', user.username)
        formData.append('skillid', challenge.skill._id)
        formData.append('taskid', challenge._id)
        formData.append('stepid', location.position)
        formData.append('phase', location.phase)
        return apiService.post(`${this.API_ENDPOINT}/img`, formData)
    }

    getAnswer(username, skillid, phase, stepid) {
        return apiService.get(`${this.API_ENDPOINT}/${username}/${skillid}/${stepid}/${phase}`)
            .then(res => res.data.usertaskprofile)
    }

}

export default new UserTaskService();

