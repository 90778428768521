import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
/**********************************************************
 File          : contentmain.js
 Classes       : ContentMain
 Responsibility: This Component class is responsible for 
 aggregating content from subscreens and present the main 
 screen to user(learner).
 Interacts With: BasicNavigation for TopMenu,
 With LandingContent for initial content. 
 TODO         : LandingContent will later also load content 
 from database.
 Organization : BasicNavigation (Top)
 LandingContent (Bottom)
 ***********************************************************/

//Importing Subscreens
import BasicNavigation from './subscreens/basicnavigation';
import DynamicContent from './subscreens/dynamiccontent';
import ModalContainer from '../modal/modalcontainer';

import skillService from '../../services/skill.service';

import Examples from './examples';
import Definitions from './definitions';
import Badges from './badges';
import Portfolio from './portfolio';
import { withTranslation } from 'react-i18next';
import userProfileService from '../../services/userprofile.service'
import { ImSpinner2 } from 'react-icons/im';
//ContentMain class
class UserContentMain extends React.Component {

  SKILL_IDS = [1, 2, 3, 4, 5]

  constructor(props) {
    super(props);
    this.state = {
      skills: [],
      modalContent: null,
      screen: ''._id,
      language: "en",
      tempState: null
    };
  }

  componentDidMount() {
    userProfileService.getUserProfile(localStorage.getItem('username')).then(
      userProfile => {
        this.setState({ language: userProfile.language } ,()=> this.changeLanguage(userProfile.language ))
     }).then( result => {

      //skillService.getSkills(this.state.language)
      skillService.getSkills('en')
                                    .then( skills => {

        skills.forEach( skill => {
          let tempSkills = this.state.skills
          tempSkills.push(skill)
          tempSkills = tempSkills.sort( (a, b) => a._id - b._id)
          this.setState({skills: tempSkills})
        })
      })

      // console.log (this.state.skills);

      // this.SKILL_IDS.forEach(id => skillService.getSkill(id, this.state.language).then(skill => {
      //   let tempSkills = this.state.skills
      //   tempSkills.push(skill)
      //   tempSkills = tempSkills.sort( (a, b) => a._id - b._id)
      //   this.setState({skills: tempSkills})
      // }))

      // console.log( this.state.skills);
    })
  }

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
  };

  handleHideModal = () => this.setState({modalContent: null})
  updateModalContent = modalContent => this.setState({ modalContent })

  navigateScreen = destination => this.setState({ screen: destination })

  getContentByState = () => {
    let language = this.state.language
    switch (this.state.screen) {
      case 'portfolio':
        return <Portfolio language={language} skills={this.state.skills} />
      case 'examples':
        return <Examples language={language} skills={this.state.skills} />
      case 'definitions':
        return <Definitions language={language} skills={this.state.skills} />
      case 'badges':
        return <Badges language={language} skills={this.state.skills} />
      case 'skills':
      default:
        return <DynamicContent language={language}
          skills={this.state.skills}
          updateModalContent={this.updateModalContent}
          saveTempState={tempState => this.setState({ tempState })}
          tempState={this.state.tempState}
          />
    }
  }

  render() {
    if  ( this.state.language !==  "" && this.state.language !== undefined ) {
        let language = this.state.language
        return (<>
          <BasicNavigation language={language} navigateTo={this.navigateScreen} />
          <Container>
            <Row>
              <Col>
                <this.getContentByState />
              </ Col>
            </Row>
          </Container>
          <ModalContainer language={language}  content={this.state.modalContent} />
        </>);
      } else {
        return (<div className='loading...'>
            <ImSpinner2 className='icon-spin' /> <br/>
              Initialization of User Profile ongoing...<br/>
        </div>)
      }  
  }
}

export default withTranslation()(UserContentMain);
