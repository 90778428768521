import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { FaHandPointer, FaCamera } from 'react-icons/fa';

import '../../styles/userprogressmain.scss';
import ProgressMarker from '../progressmarker';

class UserProgressMain extends Component {

	render() {
		let {t} = this.props
		return (<Container id='userprogressmain-component'>
			{ this.props.skills.map(skill =>
				<div key={skill._id} className='skill d-flex flex-row'>
					<div className="text">{t(skill.title.toLowerCase())}</div>
					{ !!this.props.location && this.props.withProgress && <ProgressMarker location={this.props.location[skill._id]} initialLocation={this.props.initialLocation && this.props.initialLocation[skill._id]} sequence={this.props.sequence} skill={skill}/>}
					{ !this.props.reduced && <div className="tag" onClick={() => this.props.onSkillTagged(skill)}><FaHandPointer /></div> }
					{ !this.props.reduced && <div className="camera" onClick={() => this.props.onExampleUpload(skill)}><FaCamera /></div> }
				</div>) }
		</Container>)
	}
}

//export default UserProgressMain;
export default withTranslation()(UserProgressMain);