import React, { Component } from 'react';

import '../styles/splittextbox.scss';

class SplitTextBox extends Component {

	componentDidMount() {
		this.setContent(this.props.value)
		document.getElementById('splittextbox-component').childNodes[0].focus()
	}

	componentDidUpdate() {
		this.setContent(this.props.value)
		if (this.props.value === '') {
			document.getElementById('splittextbox-component').childNodes[0].focus()
		}
	}

	setContent(content) {
		let inputs = document.getElementById('splittextbox-component').childNodes
		for (let i = 0; i < this.props.length; i++) {
			inputs[i].value = content.charAt(i)
		}
	}

	handleChange = e => {
		let { name, value } = e.target
		let inputs = document.getElementById('splittextbox-component').childNodes
		if (inputs.length > 0) {
			let text = ''
			inputs.forEach( textbox => text += textbox.value || '')
			this.props.onChange(text)
		}
		if (!!value) {
			if (+name < inputs.length - 1) {
				inputs[+name + 1].focus()
				inputs[+name + 1].select()
			} else if (+name === inputs.length - 1)
				document.getElementById('splittextbox-component').childNodes[1].parentNode.nextSibling.focus()
		}
	}

	render() {
		let content = []
		for (let i = 0; i < this.props.length; i++)
			content.push(<input type={this.props.type || 'text'} key={i} name={i} onChange={this.handleChange} maxLength={1} />)
		return <div id='splittextbox-component'>
			{ content }
		</div>
	}
}

export default SplitTextBox