import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

import PhotoTaker from '../phototaker'

import '../../styles/fileoptionchallenge.scss'
import { GrDocumentUpload } from 'react-icons/gr'
import {withTranslation} from 'react-i18next';
class FileOptionChallenge extends Component {

    MEGAPIXEL_LIMIT = 16
    SIZE_LIMIT = this.MEGAPIXEL_LIMIT * 1024 * 1024;

    constructor(props) {
        super(props)
        this.state = {
            errorMessage: null,
            isCameraOpen: false,
            fileLoaded: null,
        }
    }

    handleInputChange = (event) => {
        if (event.target.files.length > 0) {
            if (event.target.files[0].size > this.SIZE_LIMIT) {
                this.props.onAnswer(null)
                this.setState({errorMessage: 'File is too big. Select another one'});
            }
            else {
                const reader = new FileReader();
                reader.onload = data => {
                    this.saveImageTemp(data.target.result)
                };
                reader.readAsDataURL(event.target.files[0])
            }
        }
    }

    saveImageTemp = (file) => {
        this.setState({ fileLoaded: file })
    }

    launchFileUpload = () => {
        document.getElementById('image').click()
    }

    handleCameraStart = () => this.setState({ isCameraOpen: true })
    handleCameraStop = () => this.setState({ isCameraOpen: false })

    cancelImage = () => this.setState({ fileLoaded: null })
    submitImage = () => {
        const dataURI = this.state.fileLoaded
        let byteString = atob(dataURI.split(',')[1]);
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        this.props.onSelfAnswer(new Blob([ab], {type: mimeString}))
    }

    render() {
        let {t} = this.props
        return (
            <Container id='fileoptionchallenge-component'>
            { !!this.state.errorMessage && <div className='text-danger h5 mt-4'>{this.state.errorMessage}</div>}
            { !this.state.isCameraOpen && !this.state.fileLoaded && <div className='limit'>{t('uploadpicture.uploadimagelessthan16mb')}</div> }
                { !this.state.fileLoaded ?
                    <div className='d-flex flex-row'>
                        { !this.state.isCameraOpen && <div className="file-upload big-button" onClick={this.launchFileUpload}>
                            <div className='vertical-align'>
                                <GrDocumentUpload /><br/>
                                {t('uploadpicture.uploadapicture')}
                            </div>
                        </div> }
                        <PhotoTaker
                            className='take-photo'
                            onAnswer={this.saveImageTemp}
                            onCameraStart={this.handleCameraStart}
                            onCameraStop={this.handleCameraStop}
                            language={this.props.language}
                            />
                    </div>
                    :
                    <div className="canvas">
                        <p>{t("uploadpicture.pictureok")}</p>
                        <div className="photo box-shadow"><img src={this.state.fileLoaded} alt="Your file" /></div>
                        <div className="d-flex justify-content-end flex-row">
                            <div onClick={this.cancelImage} className='button button-secondary'>{t('uploadpicture.noiwanttochangeit')}</div>
                            <div onClick={this.submitImage} className='button'>{t('uploadpicture.yes')}</div>
                        </div>
                    </div>
                }
                <input type="file" id="image" name="image" className='invisible' onChange={this.handleInputChange} />
            </Container>
        )
    }
}

export default withTranslation()(FileOptionChallenge)