import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

import definitionsService from '../../services/definitions.service';

import SkillMenu from './skillmenu';

import '../../styles/definitions.scss';
import EmptyDefinitions from '../../assets/img/definitions.png';

class Definitions extends Component {

	constructor(props) {
		super(props)
		this.state = {
			definitions: {},
			selectedSkill: -1,
			collapsedAccordion: -1
		}
		let language = this.props.language
		this.changeLanguage(language);
	}

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
  };

	componentDidMount() {
		let language = this.props.language
		this.props.skills.forEach(skill =>
			definitionsService.getDefinitionTranslated(skill._id,language).then(data => {
				const { definitions } = this.state
				definitions[data.related_skillid]= data
				this.setState({ definitions })

				console.log( this.state.definitions[data.related_skillid].description );
			})
		)
	}

	skillExist = (skillID = this.state.selectedSkill) => !!this.state.definitions[skillID]
	load

	changeDefinition = (skillid) => this.setState({ selectedSkill: this.skillExist(skillid) ? skillid : -1, collapsedAccordion: -1})

	parseDescription(definition) {
		return definition.split('\n').map( str => <p>{str}</p>);
	}

	render() {
		const definition = this.state.definitions[this.state.selectedSkill]
		let {t} = this.props
		return (
			<div id='definitions-component' className='d-flex flex-row'>
				<SkillMenu skills={this.props.skills} language={this.props.language}  activeSkill={this.state.selectedSkill} onSkillSelected={this.changeDefinition} />
				{
				<div className="content">
					{
						this.skillExist() ?
						<div className="definition">
							<div className="title">{definition.title}</div>
							<div className="subtitle">{this.parseDescription(definition.description)}</div>
							<div className="subtitle">{definition.subtitle}</div>
							<Accordion defaultActiveKey={this.state.collapsedAccordion}>
							{
								definition.headings.map((heading, index) => (
										<Card>
											<Accordion.Toggle className='d-flex justify-content-between' as={Card.Header} variant="link" eventKey={index + 1}>
												<div className="title">{heading.title}</div>
												<div className="arrow"><IoIosArrowDown /></div>
											</Accordion.Toggle>
											<Accordion.Collapse eventKey={index + 1}>
												<Card.Body>
													{
														heading.details.map((detail, index) => (
															<div className="detail" key={index}>
																<span className="bullet"><IoIosArrowForward /></span>{detail.text}
															</div>
														))
													}
												</Card.Body>
											</Accordion.Collapse>
										</Card>
								))
							}
							</Accordion>
						</div>
						:
						<div className="empty d-flex flex-column justify-content-center">
							<img src={EmptyDefinitions} alt="Empty definition" />
							<div className="text">
								{t('choosea21stcenturydefinitions')}
							</div>
						</div>
					}
				</div>
				}
			</div>
		)
	}
}

//export default Definitions;

export default withRouter(withTranslation()(Definitions));
