import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import '../../styles/skillmenu.scss';

class SkillMenu extends Component {

	constructor(props) {
		super(props);
		this.changeLanguage(this.props.language)
	}	  

	changeLanguage = (lng) => {
		this.props.i18n.changeLanguage(lng);
	  };
	
	render() {
		let { t } = this.props
		return (
			<div id='skillmenu-component' className='d-flex flex-row'>
				<div className="menu flex-shrink-0">
					{
						this.props.skills.map(skill =>
							<div className={`skill ${ skill._id === this.props.activeSkill ? 'primary-button' : 'secondary-button'}`} key={skill._id} onClick={() => this.props.onSkillSelected(skill._id)}>{ t(skill.title.toLowerCase()) }</div>
						)
					}
				</div>
			</div>
		)
	}
}

export default withTranslation()(SkillMenu);