import { Component } from "react"
import { IoIosThumbsUp } from 'react-icons/io'

import '../../styles/taggingmessage.scss'
import { withTranslation } from 'react-i18next';

class TaggingMessage extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        let {t} = this.props
        return (
            <div id="taggingmessage-component" className='d-flex flex-column justify-content-center' onClick={() => this.props.updateModalContent(null)}>
                <IoIosThumbsUp />
                <div className="text">
                    {t('tagging.messages.' + this.props.skill.title.toLowerCase())}
                </div>
            </div>
        )
    }
}

export default withTranslation()(TaggingMessage)