import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import {withRouter} from 'react-router-dom';

import LanguageSelector from '../languageselector';

import authService from '../../services/auth.service';

import '../../styles/login.scss';
import Logo from '../../assets/img/assessmake21_logo.png';
import Plane from '../../assets/login/PaperPlane.png';
import LeftBush from '../../assets/login/Left Brush.png';
import BigBush from '../../assets/login/Right Brush_Big.png';
import SmallBush from '../../assets/login/Right Brush_Small.png';
import SplitTextBox from '../splittextbox';
import userService from '../../services/user.service';


class Login extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            accessCode: '',
            errorMessage: undefined
        }

        this.changeLanguage=this.changeLanguage.bind(this);
        
    }
    
    changeLanguage = (lng) => {
        this.props.i18n.changeLanguage(lng);
    };
    
    handleChangeText = text => {
        if (text.length < 7) this.setState({accessCode: text});
    }

    handleSubmit = e => {
        e.preventDefault();
        //Checking access code
        let accessCode = this.state.accessCode;
        if (accessCode.length === 6) {
            authService.loginCode(accessCode)
                .then(data => {
                        //if teacher role
                         if (data.roles[0] === "ROLE_TEACHER") {
                            return this.setState({ errorMessage: 'incorrectcode', accessCode: ''})
                        }
                        //region
                        //TODO Temp aproach: Login saving data in localStorage
                        // Goal: Save data in app state
                        // Issue: Can't be done because 'window.location.reload()' resets app state
                        Object.entries(data).forEach( entry => localStorage.setItem(entry[0], Array.isArray(entry[1]) ? entry[1].join()  : entry[1]))
                        //For transitioning to landing page
                        // added withRouter
                        // added reload method below.
                        userService.userLoggedIn(localStorage.getItem('username'))
                        this.props.history.go("/home");
                        //endregion
                        }, 
                    err => {
                        console.error('LoginCodeError', err)
                        this.setState({ errorMessage: 'incorrectcode', accessCode: ''})
                    })
        } else {
            this.setState({ errorMessage: 'codelength'})
        }
    }

    handleChangeLanguage = language => {
        this.changeLanguage(language);
    }

    render() {
        const { t } = this.props;
        return (
        <div id="login-component" className='limited-width d-flex flex-column align-items-center'>
            <LanguageSelector onChange={this.handleChangeLanguage} />
            <img src={Logo} alt="ASSESSMAKE21 logo" className='logo'/>
            <img src={Plane} alt="" className='plane'/>
            <form className='d-flex flex-column align-items-center' onSubmit={this.handleSubmit} >
                <div className='welcome h1'>{t('greeting')}</div>
                <label htmlFor="accessCode">{t('logincode')}</label>
                <SplitTextBox length={6} type='password' onChange={this.handleChangeText} value={this.state.accessCode} />
                { !!this.state.errorMessage && <div className="errorMessage">{t(this.state.errorMessage)}</div> }
                <input type="submit" className='btn' value={t('login')}/>
                <img src={LeftBush} alt="" className='leftbush'/>
                <img src={BigBush} alt="" className='bigbush'/>
                <img src={SmallBush} alt="" className='smallbush'/>
            </form>
            <div className='tagline'>{t('tagline')}</div>
        </div>
        )
    }
}

export default withRouter( withTranslation()(Login));

