import apiService from "./api.service";

class ExamplesService {

	API_ENDPOINT = 'example'

	getExample(skillid) {
		return apiService.get(`${this.API_ENDPOINT}/${skillid}`)
			.then(res => res.data.example)
	}
	getExampleTranslated(skillid,langid) {
		return apiService.get(`${this.API_ENDPOINT}/${skillid}/${langid}`)
			.then(res => res.data.example)
	}

}

export default new ExamplesService();