import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { GrLogout } from 'react-icons/gr';

import userService from '../../../services/user.service';

import '../../../styles/basicnavigation.scss'
import Logo from '../../../assets/img/assessmake21_logo.png';
import { withTranslation } from 'react-i18next';
class BasicNavigation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      tabs: [
        {
          text: 'skills',
          destination: 'skills',
          order: 1
        },
        {
          text: 'myportfolio.myportfolio',
          destination: 'portfolio',
          order: 2
        },
        {
          text: 'mybadges',
          destination: 'badges',
          order: 3
        },
        {
          text: 'examples',
          destination: 'examples',
          order: 4
        },
        {
          text: 'definitions',
          destination: 'definitions',
          order: 5
        },
      ]
    };
    let language = this.props.language
    this.changeLanguage(language);
  }

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
  };

  handleLogout = () => {
    userService.userLoggedOut(localStorage.getItem('username'))
    localStorage.clear();
    this.props.history.push("/login");
  }

  render() {
    let { t } = this.props
    return (
      <div id='basicnavigation-component' className="container">
        <div className="row topbar d-flex justify-content-between">
          <img src={Logo} alt="assessmake21 logo" />
          <button onClick={this.handleLogout}>{t('logout')} <GrLogout /></button>
        </div>
        <Tabs className='menu' defaultActiveKey="1" >
            { this.state.tabs
                .sort((a, b) => a.order - b.order)
                .map( tab => 
                  <Tab
                    key={tab.destination}
                    eventKey={`${tab.order}`}
                    title={t(tab.text)}
                    onEntering={() => this.props.navigateTo(tab.destination)} />
                )
            }
        </Tabs>
      </div>
    );
  }
}

export default withRouter(withTranslation()(BasicNavigation));

