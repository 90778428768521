import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';

import SingleOptionChallenge from './singleoptionchallenge';
import MultipleOptionChallenge from './multipleoptionchallenge';
import TextOptionChallenge from './textoptionchallenge';
import RangeOptionChallenge from './rangeoptionchallenge';
import FileOptionChallenge from './fileoptionchallenge';
import FrequencyChallenge from './frequencychallenge';
import RatingChallenge from './ratingchallenge';
import YesNoChallenge from './yesnochallenge';
import ClassmateChallenge from './classmatechallenge';
import MessageChallenge from './messagechallenge';
import ModalContainer from '../modal/modalcontainer';

import '../../styles/challengecontainer.scss';
import { ImSpinner2 } from 'react-icons/im';

class ChallengeContainer extends Component {

    CHALLENGE_TYPES = {
        SINGLE_OPTION: 1,
        MULTIPLE_OPTION: 2,
        TEXT_OPTION: 3,
        RANGE_OPTION: 4,
        FILE_OPTION: 5,
        YESNO_OPTION: 6,
        FREQUENCY_OPTION: 7,
        RATING_OPTION: 8,
        TOOLS_OPTION: 9,
        CLASSMATE_OPTION: 10,
        MESSAGE_OPTION: 11
    }

    constructor(props) {
        super(props);
        this.state = {
            answer: null,
            errorMessage: null,
            sendingFile: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.challenge.type !== 5 && !!this.state.sendingFile) {
            this.setState({ sendingFile: false })
        }
    }

    getChallengeType = (challenge) => {
        switch (challenge.type) {
            case this.CHALLENGE_TYPES.SINGLE_OPTION: return SingleOptionChallenge
            case this.CHALLENGE_TYPES.MULTIPLE_OPTION: return MultipleOptionChallenge
            case this.CHALLENGE_TYPES.TEXT_OPTION: return TextOptionChallenge
            case this.CHALLENGE_TYPES.RANGE_OPTION: return RangeOptionChallenge
            case this.CHALLENGE_TYPES.FILE_OPTION: return FileOptionChallenge
            case this.CHALLENGE_TYPES.YESNO_OPTION: return YesNoChallenge
            case this.CHALLENGE_TYPES.FREQUENCY_OPTION: return FrequencyChallenge
            case this.CHALLENGE_TYPES.RATING_OPTION: return RatingChallenge
            case this.CHALLENGE_TYPES.CLASSMATE_OPTION: return ClassmateChallenge
            case this.CHALLENGE_TYPES.MESSAGE_OPTION: return MessageChallenge
            default: 
                console.error(`ChallengeTypeError: Unknown Challenge Type [${challenge.type}]`)
                return 'div'
        }
    }

    setAnswer = (answer) => this.setState({ answer })

    handleSubmit = () => {
        let answer = this.state.answer
        const { challenge } = this.props
        if (answer === null) this.setState({errorMessage: "validanswer"})
        else {
            if (challenge.type === this.CHALLENGE_TYPES.FILE_OPTION) {
                this.setState({ sendingFile: true })
            } else if (challenge.type === this.CHALLENGE_TYPES.RATING_OPTION) {
                // Don't manipulate `answers` in this case
            }
            else {
                if (Array.isArray(answer)) {
                    if (typeof answer[0] !== 'string') {
                        answer = answer.map(item => item.tagid)
                    }
                } else if (answer.tagid > 0) answer = answer.tagid
            }
            this.setState({ answer: null, errorMessage: null }, () => this.props.onAnswer(this.props.challenge, answer))
        }
    }

    getButtonLabel = () => {

        let buttonLabel = 'done';

        // Test if userLocation2 is defined (hacky way to handle stand-alone image feature)
        if( typeof this.props.userLocation2 !== 'undefined' ) {
            const level = this.props.userLocation2.phase;
            const position = this.props.userLocation2.position;
            const nextPosition = position+1;
            const currentLevelSequence = this.props.sequence[level];

            // console.log( this.props.sequence );
            // console.log( 'current level ' + level );
            // console.log( 'current position ' + position );
            // console.log( 'next position ' + nextPosition);

            // Check if we're at the end of the sequence before trying to retrieve data
            if( nextPosition < currentLevelSequence.length) {
                const nextTask = currentLevelSequence[nextPosition];

                const nextTaskType = nextTask.type;

                if( nextTaskType !== 1 ) {
                    buttonLabel = 'next';
                }
            }
        }

        return buttonLabel;
    }

    handleSelfAnswer = (answer) => this.setState({ answer }, this.handleSubmit)

    hideDoneButton = () => this.setState({showDoneButton: false})
    showDoneButton = () => this.setState({showDoneButton: true})

    render() {
        if (!this.props.challenge) return null;
        let {t}= this.props
        return (
            <Container id='challengecontainer-component'>
				{ !!this.props.abortChallenge && <div className="back" onClick={this.props.abortChallenge}>&lt; {t('back')}</div>}
                <Row className='title'>{this.props.challenge.title}</Row>
                <Row className='answers'>{
                    React.createElement(
                        this.getChallengeType(this.props.challenge),
                            {
                                language: this.props.i18n.language,
                                challenge: this.props.challenge,
                                answer: this.state.answer,
                                onAnswer: this.setAnswer,
                                onSelfAnswer: this.handleSelfAnswer
                            }
                    )
                }</Row>
                <Row className='text-danger h4'>{t(this.state.errorMessage)}</Row>
                { this.props.challenge.type !== 5 &&
                    <Row className='submit justify-content-end'>
                        <button className='btn' onClick={this.handleSubmit}>{t(this.getButtonLabel())} &gt;</button>
                    </Row>
                }
                { this.state.sendingFile &&
                    <ModalContainer content={(
                        <div className='loading'>
                            <ImSpinner2 className='icon-spin' /> <br/>
                            {t('savingyouranswer')}<br/>
                            {t('thismaytakeawhile')}
                        </div>
                    )} />
                }
            </Container>
        )
    }
}

export default withTranslation()(ChallengeContainer);