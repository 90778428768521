import React, { Component, createRef } from 'react'
import Modal from 'react-bootstrap/Modal';

import '../styles/phototaker.scss'
import { AiFillCamera } from 'react-icons/ai'
import { RiRecordCircleLine, RiCloseLine, RiCameraSwitchFill } from 'react-icons/ri'
import { withTranslation } from 'react-i18next';


class PhotoTaker extends Component {

	MEGAPIXEL_LIMIT = 2
    SIZE_LIMIT = this.MEGAPIXEL_LIMIT * 1024 * 1024;

	videoRef = null
	photoRef = null

	constructor(props) {
		super(props);
		this.state = {
			cameraStarted: false,
			showCamera: true,
			lastPhoto: null,
			buttonMessage: 'Take a picture of your example',
			facingMode: 'environment',
			stream: null,
			nativeHeight: 0,
			nativeWidth: 0
		}
		this.videoRef = createRef(null)
		this.photoRef = createRef(null)
	}

    takePhoto = () => {
		let video = this.videoRef.current;
		this.setState({showCamera: false}, () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			let { nativeHeight, nativeWidth } = this.state
			const nativeSize = nativeHeight * nativeWidth

			if (nativeSize > this.SIZE_LIMIT) {
				const reductionScale = Math.sqrt((this.SIZE_LIMIT / nativeSize) * 0.99)
				nativeHeight = nativeHeight * reductionScale
				nativeWidth = nativeWidth * reductionScale
			}

			canvas.width = nativeWidth
			canvas.height = nativeHeight

			ctx.drawImage(video, 0, 0, nativeWidth, nativeHeight)
			this.props.onAnswer(canvas.toDataURL('image/jpeg'))
			this.stopCamera()
		})
	};

	startCamera = (facingMode = 'environment') => {
		navigator.mediaDevices
			.getUserMedia({
				video: { facingMode },
				audio: false,
				peerIdentity: 'webcam'
			})
			.then(stream => {
				const tracks = stream.getVideoTracks()
				if (!!tracks[0]) {
					const config = tracks[0].getSettings()
					this.setState({ nativeHeight: config.height, nativeWidth: config.width })
				}
				this.setState({ stream })
				let video = this.videoRef.current;
				if (video) {
					video.srcObject = this.state.stream;
					video.play();
				}
			})
		this.setState({cameraStarted: true, facingMode })
	}

	stopCamera = () => {
		if (!!this.state.stream) this.state.stream.getVideoTracks().forEach(track => track.stop())
		this.setState({cameraStarted: false})
	}

	changeCamera = () => {
		if (this.state.facingMode === 'environment') this.startCamera('user')
		else this.startCamera()
	}

    render() {
		let {t}=this.props
        return (<div id="phototaker-component">
			{ this.state.cameraStarted ?
				<Modal className="video" show={true}>
					<div id="phototaker-content">
						<video ref={this.videoRef} />
						<div onClick={this.stopCamera} className='close-button'><RiCloseLine/></div>
						<div onClick={this.takePhoto} className='take-photo'><RiRecordCircleLine/></div>
						<div onClick={this.changeCamera} className='change-camera'><RiCameraSwitchFill/></div>
						<canvas className='invisible' ref={this.photoRef} />
					</div>
				</Modal>
				:
				<div className="take-photo big-button" onClick={this.startCamera}>
					<div className="vertical-align">
						<AiFillCamera /><br/>
						{t('uploadpicture.takeapicture')}
					</div>
                </div>
			}
        </div>)
    }
}

export default  withTranslation()(PhotoTaker);

