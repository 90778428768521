import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import SkillMenu from './skillmenu';

import portfolioService from '../../services/portfolio.service';

import '../../styles/portfolio.scss';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import EmptyPortfolio from '../../assets/img/portfolio.png'

class Portfolio extends Component {

	constructor(props) {
		super(props)
		this.state = {
			portfolio: [],
			displayPortfolio: [],
			selectedSkill: -1,
			selectedLevel: -1
		}
	}

	componentDidMount() {
		portfolioService.getPortfolio(localStorage.getItem('username'), this.props.skills.map(skill => skill._id))
			.then(portfolio => {
				this.setState({ portfolio }, () => {
					Promise.all(portfolio.map(image => portfolioService.getImageTexts(image.username, image._id))).then(data => {
						const { portfolio } = this.state
						if (!!data) data.forEach((entry, index) => portfolio[index].answers = entry)
						this.setState({ portfolio })
					})
				})
			})
	}

	getSkill = (skillid) => {
		return this.props.skills[this.props.skills.map(skill => skill._id).indexOf(skillid)];
	}

	getLevelStr = (level) => {

		let response = 'Level ' + level;

		if(level === 5 ) {
			response = 'Infinite level';
		}

		return response;
	}

	changeSkill = (skillid) => this.setState({ selectedSkill: skillid, selectedLevel: -1, displayPortfolio: [] })
	changeLevel = (level) => {
		if (!isNaN(level)) {
			level = +level
			this.setState({ selectedLevel: level })
			if (this.state.selectedSkill > -1) {
				this.setState({ displayPortfolio: this.state.portfolio.filter(entry => entry.skillid === this.state.selectedSkill && entry.phase === level) })
			}
		} else this.setState({ selectedLevel: -1, displayPortfolio: [] })
	}

	render() {
		let {t}= this.props
		return (
			<div id='portfolio-component' className='d-flex flex-row'>
				<SkillMenu skills={this.props.skills} language={this.props.language} activeSkill={this.state.selectedSkill} onSkillSelected={this.changeSkill} />
				<div className="content">
					<select className="level-selector" name="level" value={this.state.selectedLevel} onChange={e => this.changeLevel(e.target.value)}>
						<option value="-1">{t("myportfolio.chooselevel")}</option>
						<option value="1">{t("myportfolio.level")} 1</option>
						<option value="2">{t("myportfolio.level")} 2</option>
						<option value="3">{t("myportfolio.level")} 3</option>
						<option value="4">{t("myportfolio.level")} 4</option>
						<option value="5">Infinite Level</option>
					</select>
					{
						this.state.selectedSkill > -1 && this.state.selectedLevel > -1 ?
						this.state.displayPortfolio.map(entry => {
							let date = new Date(entry.createdAt)
							const [ whatAnswer, whyAnswer, ratingAnswer ] = entry.answers
							return <div className='entry box-shadow container-fluid row'>
								<div className="col-6 text">
									<div className="title">{t(this.getSkill(entry.skillid).title.toLowerCase())}: {t('myportfolio.level'+entry.phase)}</div>
									<div className="text">
										<div className="whatAnswer">{whatAnswer}</div>
										<div className="whyAnswer">{whyAnswer}</div>
									</div>
									<div className="details">
										{ !isNaN(ratingAnswer) && <div className='ratingAnswer d-flex flex-row'>
											{
												[1, 2, 3, 4].map(star => star <= ratingAnswer ? 
													<div className='star active'><AiFillStar/></div>
													:
													<div className='star'><AiOutlineStar/></div>)
											}
										</div> }
										<div className="date">{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</div>
									</div>
								</div>
								<div className="col"></div>
								<div className="img col-4">
									<img src={`data:png;base64,${Buffer.from(entry.img.data).toString('base64')}`} alt="Example" />
								</div>
							</div>
						})
						:
						<div className="empty d-flex flex-column justify-content-center">
							<img src={EmptyPortfolio} alt="Empty portfolio" />
							<div className="text">
								{
									this.state.selectedSkill > -1 ?
									t("choosea21stcenturyexamples")
									:
									t("chooseaalevelforexamples")
								}
							</div>
						</div>
					}
				</div>
			</div>
		)
	}
}

//export default Portfolio;
export default withTranslation()(Portfolio);