import apiService from './api.service';

class TaggingService {

    API_ENDPOINT = 'user/tagging'

    tagSkill(skill) {
        return apiService.post(this.API_ENDPOINT, {
            username: localStorage.getItem('username'),
            skillid: skill._id
        })
    }

}

export default new TaggingService();

