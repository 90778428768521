import apiService from './api.service';
import bcryptjs from 'bcryptjs';

class AuthService {

    API_ENDPOINT = 'auth'
    CLIENT_SALT = '$2a$10$60uX4qjyRIgMz6A/XgNtZu'

    loginCode(accessCode) {
        accessCode = bcryptjs.hashSync(accessCode.toLowerCase(), this.CLIENT_SALT)
        return apiService.post(`${this.API_ENDPOINT}/codesignin`, { accessCode }, false)
            .then(res => {
                let data = res.data
                localStorage.setItem('accessToken', data.accessToken)
                return data
            })
    }

}

export default new AuthService();

