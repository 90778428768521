import apiService from "./api.service";

class BadgeService {

	API_ENDPOINT = 'user/badge'

	requestBadge(username, skillid, phase, notes) {
		return this.getBadgeBySkillidNPhase(skillid, phase).then(badge => {
            if (!!badge) apiService.put(this.API_ENDPOINT, { username, badgeid: badge._id, status: 2, notes })
        })
	}

	getBadges(skillid) {
		return apiService.get(`${this.API_ENDPOINT}/${skillid}`)
			.then(data => data.data.badges)
	}

	getBadgeBySkillidNPhase(skillid, phase) {
        return apiService.get(`${this.API_ENDPOINT}/skillid/${skillid}/phase/${phase}`).then(data => data.data.badge)
    }

	getUserBadges(username) {
		return apiService.get(`${this.API_ENDPOINT}/username/${username}`)
			.then(data => {
				data = data.data.badges
				data.sort((a,b) => b.status - a.status)
				const indexData = data.map(a => a.badgeid)
				data = data.filter((a, index) => indexData.indexOf(a.badgeid) === index)
				return data
			})
	}

}

export default new BadgeService();