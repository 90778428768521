import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import userService from '../../services/user.service';

import '../../styles/classmatechallenge.scss'

class ClassmateChallenge extends Component {

    componentDidMount() {
        userService.getUserClassmates(localStorage.getItem('username')).then(classmates => {
            classmates = classmates.filter(classmate => classmate.username !== localStorage.getItem('username'))
            this.setState({ classmates })
        })
    }

    handleSelectChange = e => {
        const { value } = e.target
        if (value === 'null') this.props.onAnswer(null)
        else this.props.onAnswer(value)
    }

    render() {
        let {t}= this.props;
        return (
            <div id='classmatechallenge-component'>
                { !!this.state && !!this.state.classmates &&
                <select name="classmate" onChange={this.handleSelectChange}>
                    <option value='null' key='null'>{t('selectclassmate')}</option>
                    { this.state.classmates.map(classmate => <option value={classmate.username} key={classmate.username}>{classmate.name}</option>) }
                    { this.state.classmates.length < 1 && <option value={false} key='null'>None</option> }
                </select>
                }
            </div>
        )
    }
}

export default withTranslation()(ClassmateChallenge)