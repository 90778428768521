import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import '../../styles/approachatend.scss';
import { withTranslation } from 'react-i18next';

import userProfileService from '../../services/userprofile.service';
import userTaggingService from '../../services/tagging.service';

import Stepper from '../stepper';
import ChallengeContainer from '../challenges/challengecontainer';
import UserProgressMain from './userprogressmain';
import TaggingMessage from '../modal/taggingmessage';
import ChallengeSummary from './challengesummary';

import Explain from '../../assets/img/explain.png';
var cloneDeep = require('lodash.clonedeep');


class ApproachAtEnd extends Component {

	constructor(props) {
		super(props)
		this.state = {
			location: '',
			challenges: {},
			challenge: null,
			loadTime: false,
			localSequence:null,
			localLocation:null,
			currentStep:null,
			initialLocation:null,
			tempLocation: null
		}
		
		let language = this.props.language
		this.changeLanguage(language);
	}

    componentWillUnmount() {
		this.props.saveTempState(this.state)
	  }

	changeLanguage = (lng) => {
		this.props.i18n.changeLanguage(lng);
	  };

	//Check the progress till now and based on the users location 
	//if some tagging is done later to the end without doing challenges
	//those challenges needs to be loaded as well in the beginning.
	//also enable the Challenges button if there are some challenges already.
	// 1. Get latest step across all skills. if that is a task of type tagging (1), then
	//we need to add the necessary number of challenges to the list
	//Note : ComponentDidMount of child (approachatend) is called before that of parent (dynamiccontent in this case)
	componentDidMount() {
		const { tempState } = this.props
		if (!!tempState) this.setState(tempState)
		else {
			let location= this.props.location;
			let skills = Object.keys(location)
			let initLocation=cloneDeep(location)
			this.setState({initialLocation:initLocation})
			this.saveInitialLocation()
			skills.forEach(skillid => {
				let skillLocation = location[skillid]
				let { challenges } = this.state
				let skillInitialLocation=initLocation[skillid]
				let currentSkill = challenges[skillid]
				//Initialize	
				
				if ( parseInt(skillLocation.position)>1 ) //only these needs to be analyzed
				{
					
					
					if (skillLocation.position>=skillInitialLocation.positionstart)
					{
					// get everything between positionstart to position and dependent steps
					let stepsLastLeg= [] 
					stepsLastLeg = this.getStepsFromLastLeg(skillInitialLocation.positionstart+1, skillLocation.position+1,skillid,skillLocation.phase)
					stepsLastLeg.forEach(step => {
						
						if (!currentSkill) currentSkill = []
						currentSkill.push(step)
						location = this.props.setPosition(skillid,step.sequence-1,skillLocation.phase)
						this.props.updateLocation(location)
						if (step.group === 1 && step.sequence>=skillInitialLocation.position+1)
							{
							if (step.dependson===0) { //first step in the sequence so perfect
							// if these steps have anything dependent add them as well
									let stepsToAdd = [] 
									stepsToAdd = this.getDependantSteps(step.sequence,skillid,skillLocation.phase)
									if (stepsToAdd.length>0)
									{
										stepsToAdd.forEach(stepDep => {
											currentSkill.push(stepDep)
											location = this.props.setPosition(skillid,stepDep.sequence-1,skillLocation.phase)
											this.props.updateLocation(location)
										});
									}
							} else { //just in case it is a step in between restart again 
								//remove the previously added step 
								
									currentSkill.pop()
									let initialStepToAdd = [] 
									initialStepToAdd=this.getInitialStepinGroup(step.dependson,skillid,skillLocation.phase)
									if (initialStepToAdd.length>0)
									{
										currentSkill.push(initialStepToAdd[0])
									}
									
									let stepsToAdd = [] 
									stepsToAdd = this.getDependantSteps(step.dependson,skillid,skillLocation.phase)
									if (stepsToAdd.length>0)
									{
										stepsToAdd.forEach(stepDep => {
											currentSkill.push(step)
											location = this.props.setPosition(skillid,stepDep.sequence-1,skillLocation.phase)
											this.props.updateLocation(location)
										});
									}
							}
						}
						challenges[skillid] = currentSkill
						this.setState({challenges})
						location= this.props.increasePosition(skillid)
						this.props.updateLocation(location)
					});
	
				}	
					// Comment this below out: Abhi  06 Aug ->
				}
			
			})	
		}
			
		
				
	}
		
	//Get the Step from the sequence array 
    //Note the array position starts from 0.
    //But as for steps, there is no step with index 0
    getCurrentStepLocal = (skillid, phase = null, position = null) => {
		const location = this.props.location[skillid]
		if (phase === null) {
		  phase = location.phase
		}
		if (position === null) {
		  position = location.position
		}
		return this.props.sequence[phase][position]
	}

	getSequenceLocal = (skillid, phase = null) => {
		const location = this.props.location[skillid]
		if (phase === null) {
			phase = location.phase
		}
		return this.props.sequence[phase]
	}

	handleProgress = (skill) => {
		const currentStep = this.props.getCurrentStep(skill._id)
		let location = this.props.location
		let language = this.props.language
		let initialLocation = this.state.initialLocation
		let initialPosition = initialLocation[skill._id].position
		let currentPosition = location[skill._id].position
		let { challenges } = this.state
		
		if (location[skill._id].phase <= 5) {
			if (currentPosition === 0) initialPosition = 0
			userProfileService.updateUserProfile(skill._id, location[skill._id].phase,currentPosition, initialPosition)
			.then(() => {
				this.props.updateModalContent(<TaggingMessage skill={skill} language={language} updateModalContent={this.props.updateModalContent} />)
				this.props.updateLocation(location)
				userTaggingService.tagSkill(skill)
			})
			.catch(err => console.error('handleProgress',err))
			if (currentStep.type !== 1) {
				let currentSkillChallenges = challenges[skill._id]
				if (!currentSkillChallenges) currentSkillChallenges = []
				currentSkillChallenges.push(currentStep) //Add current step TODO: Somehow gets called multiple times for same challenge
				if (currentStep.group===1) //The current step does not depend on anything -> means we need not look for previous
				{
					if (currentStep.dependson>0) {
							let initalStepToAdd = [] 
							initalStepToAdd=this.getInitialStepinGroup(currentStep.dependson,skill._id,location[skill._id].phase)
							if (initalStepToAdd.length>0)
							{
								currentSkillChallenges.push(initalStepToAdd[0])
								location= this.props.increasePosition(skill._id)
								this.props.updateLocation(location)
							}
					}
					let stepsToAdd = [] 
					stepsToAdd = this.getDependantSteps(currentStep.sequence,skill._id,location[skill._id].phase)
					stepsToAdd.forEach(step => {
						currentSkillChallenges.push(step)
						location = this.props.increasePosition(skill._id)
						this.props.updateLocation(location)
					});
				}
				challenges[skill._id] = currentSkillChallenges
				this.setState({challenges})
			}				
			location = this.props.increasePosition(skill._id)
		}
	}

	loadChallenges = () => {
		let step = this.getNextStep()
		if (!!step) {
			this.props.loadChallenge(step.skill, step.phase, step.sequence - 1, challenge => {
				if (!!challenge) {
					challenge.skill = this.props.skills[this.props.skills.map(skill => skill._id).indexOf(step.skill._id)]
					this.setState({ location: 'challenges', challenge, currentStep:step})
				}
			})
		} else this.setState({ location: 'progressSummary'})
	}

	handleChallengeAnswer = (challenge, answer) => {
		this.props.onChallengeAnswer(challenge, answer, () => {
			this.state.initialLocation[challenge.skill._id].position =  this.props.location[challenge.skill._id].position 
			this.removeStepByState()
			this.loadChallenges()
		}, this.state.currentStep)
	}

	startSummary = () => {
		if (Object.keys(this.state.challenges).length > 0) this.setState({ location: 'challengeSummary'})
	}

	getNextStep = (skillid) => {
		let { challenges } = this.state
		if (skillid === undefined) {
			let skills = Object.keys(challenges)
			if (skills.length > 0) return this.getNextStep(skills[0])
			else return null
		} else {
			if(challenges !== undefined)
			{
				if (!!challenges[skillid] && challenges[skillid].length > 0) {
					//console.log("Return from getNextStep:",challenges[skillid][0], this.props.skills[this.props.skills.map(skill => skill._id).indexOf(+skillid)])
					return {...challenges[skillid][0], skill: this.props.skills[this.props.skills.map(skill => skill._id).indexOf(+skillid)]}
					
				} else return null
			} else return null
		}
	}

	getDependantSteps=(stepsequence,skillid,phase)=> {
		let sequence = this.props.getSequenceParent(skillid,phase) // this.getSequenceLocal(skillid,phase)
		let dependentSteps = []
		dependentSteps=sequence.filter(function (e) {
			return (e.type!==1 && e.dependson === stepsequence && e.group===1);
		});
		return dependentSteps
	}
	getInitialStepinGroup = (stepsequence, skillid, phase) => {
		let sequence = this.props.getSequenceParent(skillid,phase)
		let initialSteps = []
		initialSteps= sequence.filter(function (e) {
			return (e.type!==1 && e.sequence === stepsequence && e.group===1);
		});
		return initialSteps
	}

	getStepsFromLastLeg=(startposition,currentposition,skillid,phase)=> {
		let sequence = this.props.getSequenceParent(skillid,phase) //this.getSequenceLocal(skillid,phase)
		let lastLegSteps = []
		lastLegSteps=sequence.filter(function (e) {
			return ( e.type!==1 && e.sequence>=startposition && e.sequence <= currentposition );
		});
		return lastLegSteps
	}

	getNextStepFromSequence = (skillid,phase,position) => {
		return this.props.getCurrentStep(skillid,phase,position)
	}

	removeStepByState = () => {
		let { challenges, currentStep } = this.state
		if (challenges === {} || challenges === null) return false
		let currentSkillId = currentStep.skill._id
		if (challenges[currentSkillId].length <= 1) {
			delete challenges[currentSkillId]
		} else {
			challenges[currentSkillId] = challenges[currentSkillId].slice(1)
		}
		this.setState({ challenges })
		return true
	}

	removeStep = (skillid) => {
		let { challenges } = this.state
		if (challenges === {} || challenges === null) return false
		if (skillid === undefined) {
			let skills = Object.keys(challenges)
			if (skills.length > 0) {
				 this.removeStep(skills[0])
			} else return false
		} else {
			if (!!challenges[skillid]) {
				if (challenges[skillid].length === 0 || challenges[skillid].length === 1) {
					delete challenges[skillid]
				} else if (challenges[skillid].length > 1) {
					challenges[skillid] = challenges[skillid].slice(1)
				} else return false
			} else return false
		}
		this.setState({ challenges })
		return true
	}

	saveInitialLocation = () => this.setState({ tempLocation: JSON.parse(JSON.stringify(this.props.location)) })

	navigateTo = (location = '') => this.setState({location})

	getContent = () => {
		
		switch (this.state.location) {
			case 'challengeSummary':
				return <ChallengeSummary
						skills={this.props.skills.map(skill => {skill.challenges = this.state.challenges[skill._id]; return skill})}
						goBack={this.navigateTo}
						goNext={this.loadChallenges}
						/>
			case 'challenges': 
				/*
				 * location is an object containing student's current position for each skill (phase, position, positionstart)
				 * sequence is an object containing a set of arrays (one for each level) of the challenges
				 * challenge is the current challenge that the student should be presented with
				 */
				let { sequence, tempAnswers } = this.props
				let { challenge } = this.state
				let currentStep = this.getNextStep();
				let shortSequence = []
				let shortSequenceIndex = -1
				let answerIndex = -1

				if (!!sequence && !!challenge && !!currentStep) {

					// The purpose of this block is the figure out how many steps the Stepper should display.
					//
					// Types of challenge are as follows:
					// 1 = tagging
					// 2 = normal challenge
					// 3 = example upload (any of the 4 steps)
					if ( currentStep.type === 3) {

						const phase = currentStep.phase;

						const currentPosition = currentStep.sequence;
						const shortSequenceBegin = sequence[phase]
														.slice(0, currentPosition)
															.sort((a, b) => b.sequence - a.sequence)
						const shortSequenceBeginIndex = shortSequenceBegin.length - 1 - shortSequenceBegin.findIndex(step => step.type !== 3) + 1
						const shortSequenceEnd = sequence[phase].slice(currentPosition)
						const shortSequenceEndIndex = shortSequenceBegin.length + shortSequenceEnd.findIndex(step => step.type !== 3)
						shortSequence = sequence[phase].sort((a, b) => a.sequence - b.sequence).slice(shortSequenceBeginIndex, shortSequenceEndIndex)
						shortSequenceIndex = shortSequence.map(step => step.sequence).indexOf(currentPosition)

						if (shortSequenceIndex === -1) shortSequenceIndex = shortSequence.length - 1
						if (!!currentStep && currentStep.dependson > 0) {
							answerIndex = tempAnswers.findIndex(answer => answer.step === currentStep.dependson && answer.skillid === challenge.skill._id)
						}
					}

				}
				return (
					<>
						{ !!currentStep && currentStep.type === 3 &&
						<Row>
							<Stepper
							total={shortSequence.length}
							active={shortSequenceIndex + 1} />
						</Row>
						}
						<Row>
						<Col>
							<ChallengeContainer
							challenge={this.state.challenge}
							onAnswer={this.handleChallengeAnswer}
							sequence={this.props.sequence}
							userLocation2={this.props.location[this.state.challenge.skill._id]}
							userLocation={this.props.userLocation} />
						</Col>
						{ !!currentStep && currentStep.type === 3 && currentStep.dependson > 0 && 
							<Col xs={6} className='image'>
							{ !!this.props.tempAnswers[answerIndex] ? 
								<img src={this.props.tempAnswers[answerIndex].answer} alt='Example answer'></img>
								:
								<FaSpinner className='icon-spin'/>
							}
							</Col>
						}
						</Row>
					</>
				)
			case 'progressSummary':
				return (
					<div className='d-flex flex-column'>
						<div className="row">
							<UserProgressMain
							skills={this.props.skills}
							onSkillTagged={this.handleProgress}
							location={this.props.location}
							initialLocation={this.state.tempLocation}
							sequence={this.props.sequence}
							withProgress={true}
							reduced={true}/>
						</div>
						<div className="primary-button row align-self-end start-again" onClick={() => {this.saveInitialLocation(); this.navigateTo()}}>Start again</div>
					</div>
				)
			case '':
			default: return (
				<div className='container row'>
					<div className="col ">
						<UserProgressMain
						skills={this.props.skills}
						onSkillTagged={this.handleProgress}
						onExampleUpload={this.props.onExampleUpload}
						location={this.props.location}
						sequence={this.props.sequence}
						withProgress={false} />
					</div>
					<div className="col assistant">
						<img src={Explain} alt="Assistant" />
						<div className='text box-shadow'>{this.props.t('inputwhichskills')}</div>
						<div className={`complete ${ Object.keys(this.state.challenges).length > 0 ? 'secondary-button' : 'disabled-button'}`} onClick={this.startSummary}>{this.props.t('completechallenges')}</div>
					</div>
				</div>
			)
		}
	}

	render() {
		let sequenceLocal = this.props.sequence;
		if (sequenceLocal!=null) {
			return (
				<div id='approachatend-component' className="container">
				  <this.getContent/>
				</div>
			  );
		} else
		{
			return <div>fetching data...</div>
		}
		
	}
}

//export default ApproachAtEnd
export default  withTranslation()(ApproachAtEnd);