import apiService from "./api.service";

class UserService {

	API_ENDPOINT = 'user'

	getUser(username) {
		return apiService.get(`${this.API_ENDPOINT}/${username}`)
			.then(res => res.data.user)
	}

	getUserSequence(user) {
		return apiService.get(`${this.API_ENDPOINT}/activitymodel/${user}`)
			.then(res => {
				let sequence = res.data.activitymodel.phasecontainer
				let r = {}
				sequence.forEach(phase => r[phase.phase] = phase.steps)
				return r
			})
	}

	logUserActivity(username, type ,activitydetail) {
		return apiService.post(`${this.API_ENDPOINT}/log`, { username, type, activitydetail })
	}

	userBadgeReady(username, phase, skillid) {
		return apiService.post(`${this.API_ENDPOINT}/badge`, { username, phase, skillid, status: 1, notes: 'Good work!' })
	}

	userPhaseComplete(username, phase, skillid) {
		return apiService.post(`${this.API_ENDPOINT}/phase`, { username, phase, skillid, status: 1, notes: `Phase ${phase} completed for skill #${skillid}` })
	}

	userLoggedIn(username) {
		return this.logUserActivity(username, 1, 'Logged in')
	}

	userLoggedOut(username) {
		return this.logUserActivity(username, 2, 'Logged out')
	}

	getUserClassmates(username) {
		return apiService.get(`${this.API_ENDPOINT}/${username}/classmates`)
			.then(data => data.data.classmates)
	}

}

export default new UserService();