import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import '../../styles/textoptionchallenge.scss';

class TextOptionChallenge extends Component {

    render() {
        return (
            <Container id='textoptionchallenge-component'>
                <textarea value={this.props.answer || ''} onChange={ (e) => this.props.onAnswer(e.target.value || null) } />
            </Container>
        )
    }
}

export default TextOptionChallenge;